import React, { useEffect, useState } from "react";
import styles from "../CreateNotification/style.module.css";
import { Input, Select } from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import pushIcon from "../../../assets/icons/pushIcon.svg";

const ViewPushDetails = ({ setCreate, type, search, page }) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const { id } = useParams();
  const [notification, setNotification] = useState({});
  const [formattedReleaseDate, setFormattedReleaseDate] = useState("");
  console.log("notification", notification);
  useEffect(() => {
    if (id) {
      userRequest(`/api/notification/getNotificationbyId?_id=${id}`).then(
        (res) => {
          setNotification(res.data.data);
          const formattedDate = new Date(res.data.data?.releaseDate)
            .toISOString()
            .split("T")[0];
          setFormattedReleaseDate(formattedDate);
        }
      );
    }
  }, []);
  const convertToTimeInputFormat = (timeString) => {
    if (!timeString) return "";
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }
    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }
    return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
  };

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headDiv}></div>
      <div className={styles.container1}>
        <p className="font-bold text-xl md:text-base">
          <Link
            to="/Notification?tab=previous"
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Notifications
          </Link>{" "}
          <span className="mx-2">&gt; </span>
          <span> Details</span>
        </p>
        <h1 className="font-bold text-2xl mb-3">
          {notification?.status} Notification
        </h1>
        <div className={styles.resetPassword}>
          <p>Title*</p>
          <Input
            placeholder="Title (max. 50 characters)"
            value={notification?.title}
            disabled
          />
          <p>Body*</p>
          <Input
            placeholder="Add Body (max. 150 characters)"
            value={notification?.body}
            disabled
          />
          <p>City*</p>
          <Input
            placeholder="City"
            type="text"
            value={notification?.cities}
            disabled
          />
          <div className={styles.dateDiv}>
            <p>Release on:</p>
            <Input
              type="date"
              min={currentDate}
              value={formattedReleaseDate}
              disabled
            />
            <Input
              type="time"
              value={convertToTimeInputFormat(notification?.releaseTime)}
              disabled
            />
          </div>
        </div>
      </div>
      <div className={`${styles.container2} mb-40`}>
        <div className={`${styles.preview} mt-40`}>
          <p>Preview</p>
          <div className={styles.previewInner}>
            <span className={styles.spanIcon}>
              <img src={pushIcon} alt="" />
            </span>
            <div className="w-[75%] relative left-24">
              <h2 className="font-bold text-[#333333] text-2xl">
                {" "}
                {notification?.title}
              </h2>
              <p className="text-[#333333] text-lg"> {notification?.body}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPushDetails;
