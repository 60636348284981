import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Login from "../Pages/Auth/Login/Login";

import Massage from "../Pages/Massege/Massage";
import PushNotification from "../Pages/PushNotification/PushNotification";
import Settings from "../Pages/Settings/Settings";
import PartnerAd from "../Pages/Advertisment/PartnerAd/PartnerAd";
import ViewAdDetails from "../Pages/Advertisment/PartnerAd/ViewAdDetails";
import CreateMassage from "../Pages/Massege/CreateMassage/CreateMassage";
import Chat from "../Pages/Massege/Chat/Chat";
import Profile from "../Pages/Profile/Profile";
import EditProfile from "../Pages/Profile/EditProfile/EditProfile";
import ViewPushDetails from "../Pages/PushNotification/Previous/ViewDetails";
import ViewAdStats from "../Pages/Advertisment/ViewStats/ViewAdStats";
import RequestedAds from "../Pages/Advertisment/PartnerAd/Overview/RequestedAds/RequestedAds";

const Router = () => {
  const token = getTokenFromCookie();
  const navigate = useNavigate();
  const location = useLocation();

  function getTokenFromCookie() {
    const cookieString = document.cookie;
    const cookies = cookieString.split(";");

    for (const cookie of cookies) {
      const [name, value] = cookie.split("=").map((c) => c.trim());
      if (name === "token") {
        return value;
      }
    }

    return null; // Return null if the token cookie is not found
  }
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else if (
      (token && location.pathname === "/") ||
      (token && location.pathname === "/login")
    ) {
      // Redirect user to the desired route
      navigate("/Dashboard");
    }
  }, [location.pathname, token, navigate]);

  const routes = [
    { path: "/", element: <Login /> },
    {
      path: "/Dashboard",
      element: (
        <PrivateRoute>
          {" "}
          <Dashboard />
        </PrivateRoute>
      ),
    },
    {
      path: "/Messages",
      element: (
        <PrivateRoute>
          <Massage />
        </PrivateRoute>
      ),
    },
    {
      path: "/CreateMessages",
      element: (
        <PrivateRoute>
          <CreateMassage />
        </PrivateRoute>
      ),
    },
    {
      path: "/Messages/Chat/:id",
      element: (
        <PrivateRoute>
          <Chat />
        </PrivateRoute>
      ),
    },
    {
      path: "/Notification",
      element: (
        <PrivateRoute>
          <PushNotification />
        </PrivateRoute>
      ),
    },
    {
      path: "/Notification/viewDetails/:id",
      element: (
        <PrivateRoute>
          <ViewPushDetails />
        </PrivateRoute>
      ),
    },
    {
      path: "/Settings",
      element: (
        <PrivateRoute>
          <Settings />
        </PrivateRoute>
      ),
    },
    {
      path: "/Partner",
      element: (
        <PrivateRoute>
          <PartnerAd />
        </PrivateRoute>
      ),
    },
    {
      path: "/Partner/RequestedAds/:id",
      element: (
        <PrivateRoute>
          <RequestedAds />
        </PrivateRoute>
      ),
    },
    {
      path: "/Partner/viewDetails/:id",
      element: (
        <PrivateRoute>
          <ViewAdDetails />
        </PrivateRoute>
      ),
    },
    // {
    //   path: "/Partner/profile/:id",
    //   element: (
    //     <PrivateRoute>
    //       <ViewPartnerProfile />
    //     </PrivateRoute>
    //   ),
    // },
    {
      path: "/Partner/viewstat/:id",
      element: (
        <PrivateRoute>
          <ViewAdStats />
        </PrivateRoute>
      ),
    },
    {
      path: "/Profile",
      element: (
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      ),
    },
    {
      path: "/EditProfile",
      element: (
        <PrivateRoute>
          <EditProfile />
        </PrivateRoute>
      ),
    },

    { path: "/login", element: <Login /> },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default Router;
