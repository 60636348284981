import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { BiFilter, BiSearch } from "react-icons/bi";
import Overview from "./Overview/Overview";
import RequestAd from "./RequestAd/RequestAd";
import { Helmet } from "react-helmet";
import HistoryAd from "./HistoryAd/HistoryAd";
import CreateAd from "./CreateAd/CreateAd";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartnerAdData } from "../../../Redux/Advertisement/Partner/PartnerAdReducer";
import AdFilter from "../AdFilter/AdFilter";
import { userRequest } from "../../../requestMethod";
const PartnerAd = () => {
  const [createAd, setCreateAd] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [type, setType] = useState("Overview");
  const [adType, setAdType] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [releaseStartDate, setReleaseStartDate] = useState("");
  const [releaseEndDate, setReleaseEndDate] = useState("");
  const [createdStartDate, setCreatedStartDate] = useState("");
  const [createdEndDate, setCreatedEndDate] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const ads =
    useSelector((store) => store.PartnerAdReducer.partnerAd.data) || [];
  const { overViewCount, requestedCount, historyCount } = useSelector(
    (store) => store.PartnerAdReducer.partnerAd
  );
  console.log("overViewCount", overViewCount, requestedCount);
  const total = useSelector(
    (store) => store.PartnerAdReducer.partnerAd.totalData
  );
  const loading = useSelector((store) => store.PartnerAdReducer.isLoading);

  useEffect(() => {
    dispatch(
      fetchPartnerAdData(
        type,
        search,
        page,
        adType,
        releaseStartDate,
        releaseEndDate,
        createdStartDate,
        createdEndDate
      )
    );
  }, [
    type,
    search,
    dispatch,
    page,
    adType,
    releaseStartDate,
    releaseEndDate,
    createdStartDate,
    createdEndDate,
  ]);
  const [data, setData] = useState({});
  const userId = JSON.parse(localStorage.getItem("userId"));
  const getData = () => {
    userRequest
      .get(`/partner/business/getBusiness?_id=${userId}`)
      .then((res) => {
        setData(res.data.data);
        console.log("Fetched Data: ", res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    // Clear ads data when type changes to prevent showing old data
    dispatch({ type: "CLEAR_PARTNER_AD_DATA" });
  }, [type, dispatch]);

  if (createAd) {
    return (
      <CreateAd
        setCreateAd={setCreateAd}
        data={data}
        initialPhoto={data.photos[0]}
      />
    );
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.spin}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
        </div>
      );
    }

    switch (type) {
      case "Overview":
        return (
          <Overview ads={ads} setPage={setPage} total={total} page={page} />
        );
      case "History":
        return (
          <HistoryAd ads={ads} setPage={setPage} total={total} page={page} />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.MainContainer}>
      <Helmet>
        <title>Advertisements - NETME</title>
      </Helmet>
      <div className={styles.firstDiv}>
        <h1 className="hidden text-3xl font-bold md:block md:pt-5 md:pr-5 lg:pt-0 lg:pr-0">
          Advertisement
        </h1>

        <div className={styles.filterDiv}>
          <div className="hidden md:block">
            <InputGroup w={200}>
              <Input
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputRightElement>
                <BiSearch color="green.500" />
              </InputRightElement>
            </InputGroup>
          </div>
          <AdFilter
            type={type}
            page={page}
            search={search}
            setAdType={setAdType}
            adType={adType}
            filterApplied={filterApplied}
            setFilterApplied={setFilterApplied}
            setReleaseStartDate={setReleaseStartDate}
            releaseStartDate={releaseStartDate}
            setReleaseEndDate={setReleaseEndDate}
            releaseEndDate={releaseEndDate}
            setCreatedStartDate={setCreatedStartDate}
            createdStartDate={createdStartDate}
            setCreatedEndDate={setCreatedEndDate}
            createdEndDate={createdEndDate}
            setStatus={setStatus}
            status={status}
          />
          <div className="hidden md:block">
            <Button bg="#8CC9E9" onClick={() => setCreateAd(true)}>
              Create New Advertisement
            </Button>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-0">
        <h1 className="pt-28 text-3xl font-bold md:hidden"> Advertisement</h1>
        <div className="flex flex-col sm:flex-row md:hidden my-5 gap-4 sm:gap-10">
          <InputGroup w={230}>
            <Input
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement>
              <BiSearch color="green.500" />
            </InputRightElement>
          </InputGroup>
          <div className="block md:hidden">
            <Button bg="#8CC9E9" onClick={() => setCreateAd(true)}>
              Create New Advertisement
            </Button>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab
              className={styles.tabPanel}
              onClick={() => setType("Overview")}
            >
              Overview{" "}
              <span
                id={type === "Overview" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {overViewCount ? overViewCount : 0}
              </span>
            </Tab>
            &nbsp; &nbsp; &nbsp;
            <Tab className={styles.tabPanel} onClick={() => setType("History")}>
              History{" "}
              <span
                id={type === "History" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {historyCount ? historyCount : 0}
              </span>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>{renderContent()}</TabPanel>
            <TabPanel>{renderContent()}</TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default PartnerAd;
