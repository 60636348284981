export const adjustedDate = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};
export const extractMonthAndYear = (dateString) => {
  // Regular expression to match the date format
  const regex = /s.*?(\d{2})\/(\d{2})/;
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Months are 0-based, so add 1
  const year = date.getFullYear();

  // Validate month and year
  // if (month < 1 || month > 12 || year < new Date().getFullYear()) {
  //   return null; // Or handle the case of invalid date
  // }

  return `${month}/${year.toString().slice(-2)}`;
};

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Get the day, month, and year parts from the date
  const day = date.getDate();
  const month = date.toLocaleDateString("en-US", { month: "long" });
  const year = date.getFullYear();

  // Function to add "st", "nd", "rd", or "th" to the day
  function getDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + "th";
    }
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  }

  const formattedDate = getDayWithSuffix(day) + " " + month + ", " + year;
  return formattedDate;
}
