import { createSlice } from "@reduxjs/toolkit";
import { userRequest } from "../../../requestMethod";

const initialState = {
  partnerAd: [],
  isLoading: false,
  error: null,
};
const userSlice = createSlice({
  name: "partnerAd",
  initialState,
  reducers: {
    getPartnerAdDataRequest: (state) => {
      state.isLoading = true;
    },
    getPartnerAdDataSuccess: (state, action) => {
      state.partnerAd = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getPartnerAdDataFailure: (state, action) => {
      state.partnerAd = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    createPartnerAdDataRequest: (state) => {
      state.isLoading = true;
    },
    createPartnerAdDataSuccess: (state, action) => {
      state.partnerAd = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    createPartnerAdDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updatePartnerAdDataRequest: (state) => {
      state.isLoading = true;
    },
    updatePartnerAdDataSuccess: (state, action) => {
      state.partnerAd = [];
      state.isLoading = false;
      state.error = null;
    },
    updatePartnerAdDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getPartnerAdDataRequest,
  getPartnerAdDataSuccess,
  getPartnerAdDataFailure,
  createPartnerAdDataRequest,
  createPartnerAdDataSuccess,
  createPartnerAdDataFailure,
  updatePartnerAdDataRequest,
  updatePartnerAdDataSuccess,
  updatePartnerAdDataFailure,
} = userSlice.actions;

export default userSlice.reducer;
const partnerId = JSON.parse(localStorage.getItem("userId"));
export const fetchPartnerAdData =
  (
    type,
    search,
    page,
    adType,
    releaseStartDate,
    releaseEndDate,
    createdStartDate,
    createdEndDate,
    partnerId,
    status
  ) =>
  async (dispatch) => {
    try {
      dispatch(getPartnerAdDataRequest());
      const response = await userRequest.get(
        `/partner/ads/getAllAds?page=${page}&limit=10&search=${search}&status=${type}&adType=${adType}&releaseStartDate=${releaseStartDate}&releaseEndDate=${releaseEndDate}&createdStartDate=${createdStartDate}&createdEndDate=${createdEndDate}&partnerId=${partnerId}&adStatus=${status}`
      );
      dispatch(getPartnerAdDataSuccess(response.data));
      console.log("response.data", response.data);
      return response.data;
    } catch (error) {
      dispatch(getPartnerAdDataFailure(error.message));
      return error.message;
    }
  };

export const createPartnerAdData =
  (
    data,
    type,
    search,
    page,
    adType,
    releaseStartDate,
    releaseEndDate,
    createdStartDate,
    createdEndDate
  ) =>
  async (dispatch) => {
    try {
      dispatch(createPartnerAdDataRequest());
      const res = await userRequest.post("partner/ads/createAds", data);
      dispatch(createPartnerAdDataSuccess(res.data));
      dispatch(
        fetchPartnerAdData(
          (type = "Overview"),
          search,
          (page = "1"),
          adType,
          releaseStartDate,
          releaseEndDate,
          createdStartDate,
          createdEndDate,
          partnerId
        )
      );
      window.location.reload();
      return res.data;
    } catch (error) {
      dispatch(createPartnerAdDataFailure(error.message));
      throw new Error(error.response?.data?.message || error.message);
    }
  };

export const updatePartnerAdData = (data) => async (dispatch) => {
  try {
    dispatch(updatePartnerAdDataRequest());
    const res = await userRequest.put(`/partner/ads/updateAdsStatus`, data);
    dispatch(updatePartnerAdDataSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(updatePartnerAdDataFailure(error.message));
    return error.message;
  }
};
export const deletePartnerAdData = (data) => async (dispatch) => {
  try {
    dispatch(updatePartnerAdDataRequest());
    const res = await userRequest.delete(`/partner/ads/deleteAd/${data?.adId}`);
    dispatch(updatePartnerAdDataSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(updatePartnerAdDataFailure(error.message));
    return error.message;
  }
};
