import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import crossImg from "../../assets/crossImg.svg";
import styles from "../Advertisment/PartnerAd/style.module.css";
import { useDispatch } from "react-redux";
import { message } from "antd";
import {
  fetchNotificationData,
  updateNotificationData,
} from "../../Redux/PushNotification/NotificationReducer";

const DeleteModel = ({
  notificationId,
  title,
  type,
  page,
  // search = "",
  // releasStartDate = "",
  // releaseEndDate = "",
  // city = "",
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const partnerId = JSON.parse(localStorage.getItem("userId"));
  return (
    <>
      <p onClick={onOpen} className={`${styles.blackBtn31}`}>
        Cancel
      </p>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="40vw">
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <div className={styles.deleteModeldiv}>
            <img src={crossImg} alt="" />
            <p>
              Are your sure want to delete this
              <br />
              push notification??
            </p>
            <h5>{title}</h5>
          </div>

          <ModalFooter>
            <div className={styles.modelFooter}>
              <Button
                variant="solid"
                color="black"
                mr={3}
                onClick={() =>
                  dispatch(
                    updateNotificationData({ deleted: true, notificationId })
                  ).then(() => {
                    dispatch(
                      fetchNotificationData(
                        (type = "Pending"),
                        "",
                        (page = "1"),
                        "",
                        "",
                        "",
                        partnerId
                      )
                    ).then(() => {
                      onClose();
                      message.success("Notification deleted successfully.");
                      // Subscription has been deleted
                    });
                  })
                }
              >
                Yes
              </Button>
              <Button variant="outline" color="black" onClick={onClose}>
                No
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModel;
