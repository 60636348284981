import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userRequest } from "../../../../../requestMethod";
import styles from "./styles.module.css";
import suggestionList from "../../../../../assets//suggestionList.png";
import pizza from "../../../../../assets/pizza.jpg";
import image1 from "../../../../../assets/image1.png";
import location1 from "../../../../../assets/location1.png";
import stars from "../../../../../assets/stars.png";
import inFeedList from "../../../../../assets/inFeedList.png";
import Ad from "../../../../../assets/ad.png";
import Cross from "../../../../../assets/cross.png";
import { Button, Modal } from "antd";
const RequestedAds = ({ id, onClose, isModalVisible }) => {
  // const { id } = useParams();
  const [adData, setAdData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdData = async () => {
      try {
        const response = await userRequest.get(
          `/partner/ads/getSingleRequestedAds/${id}`
        );
        const singleAd = response.data.data;
        setAdData(singleAd);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAdData();
  }, [id, isModalVisible]);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <Modal visible={isModalVisible} onCancel={onClose} footer={[]}>
      <div>
        {adData.adType === "Suggestion" && (
          <div className={styles.imageContainer}>
            <img
              src={suggestionList}
              alt="Suggestion List"
              className={styles.suggestionImage}
            />
            <div className="flex justify-center">
              <p className="text-center px-3 md:px-0 font-bold text-md mt-2 mb-5 italic max-w-[450px]">
                Please note that it can take up to 24 hours. for your ad to be
                approved.
              </p>
            </div>
            <div className={styles.imageInside}>
              <div
                className={`${styles.previewText} pl-2 flex items-center flex-col`}
              >
                <div className="relative right-4 mt-3">
                  <p className={styles.para1}>Here are some </p>
                  <p className={styles.para1}>suggestions for you !</p>
                  <p className="mb-3">Discover for each place.</p>
                </div>
              </div>
              <div>
                <div className={styles.previewInputImage}>
                  <img
                    src={adData?.image || pizza}
                    className={`w-[250px] h-[140px] object-cover rounded-[25px] mb-3 ${styles.sugImage}`}
                    alt="Uploaded"
                  />
                </div>
                <div className="flex flex-col">
                  <div className={`flex justify-between`}>
                    <p className="mb-1 font-bold">{adData.name}</p>
                    <div className={styles.locations}>
                      <svg
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.7127 0.540527C4.37909 0.546671 3.10244 1.0671 2.16284 1.98764C1.22323 2.90818 0.697397 4.15365 0.700699 5.45081C0.700699 9.29788 5.24205 13.2712 5.43489 13.4365C5.51153 13.5025 5.61034 13.539 5.7127 13.539C5.81505 13.539 5.91387 13.5025 5.99051 13.4365C6.18144 13.2694 10.7247 9.29696 10.7247 5.45081C10.728 4.15365 10.2022 2.90818 9.26256 1.98764C8.32295 1.0671 7.04631 0.546671 5.7127 0.540527ZM5.7127 8.12417C5.16193 8.12417 4.62352 7.96531 4.16557 7.66768C3.70762 7.37005 3.35069 6.94702 3.13991 6.45208C2.92914 5.95714 2.87399 5.41252 2.98145 4.8871C3.0889 4.36167 3.35412 3.87904 3.74357 3.50023C4.13303 3.12142 4.62923 2.86344 5.16942 2.75893C5.70961 2.65442 6.26953 2.70806 6.77838 2.91307C7.28723 3.11808 7.72215 3.46525 8.02815 3.91069C8.33414 4.35612 8.49746 4.87981 8.49746 5.41553C8.4967 6.13368 8.20307 6.8222 7.68099 7.33001C7.15891 7.83782 6.45103 8.12343 5.7127 8.12417Z"
                          fill="#333333"
                        />
                      </svg>

                      <p>2km</p>
                    </div>
                  </div>

                  <div className={`flex gap-1 ${styles.star}`}>
                    <svg
                      width="69"
                      height="9"
                      viewBox="0 0 69 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.86803 7.83029L2.88009 8.87228C2.78362 8.92298 2.6749 8.94567 2.56621 8.9378C2.45752 8.92993 2.3532 8.89181 2.26505 8.82775C2.17689 8.76369 2.10842 8.67624 2.06737 8.57529C2.02632 8.47434 2.01434 8.36392 2.03277 8.25652L2.41269 6.04303L0.804671 4.47446C0.726535 4.39843 0.671239 4.30204 0.645042 4.19622C0.618845 4.09039 0.622792 3.97934 0.656436 3.87564C0.690081 3.77194 0.75208 3.67973 0.835417 3.60944C0.918754 3.53915 1.0201 3.49359 1.12799 3.47791L3.35092 3.15545L4.34489 1.14093C4.39315 1.04328 4.46775 0.961081 4.56027 0.903606C4.65279 0.846132 4.75954 0.815674 4.86846 0.815674C4.97738 0.815674 5.08412 0.846132 5.17664 0.903606C5.26916 0.961081 5.34377 1.04328 5.39203 1.14093L6.38514 3.15545L8.60807 3.47791C8.71595 3.49359 8.8173 3.53915 8.90064 3.60944C8.98398 3.67973 9.04598 3.77194 9.07962 3.87564C9.11327 3.97934 9.11721 4.09039 9.09101 4.19622C9.06482 4.30204 9.00952 4.39843 8.93139 4.47446L7.32337 6.04303L7.70329 8.25652C7.72172 8.36392 7.70973 8.47434 7.66868 8.57529C7.62764 8.67624 7.55916 8.76369 7.47101 8.82775C7.38286 8.89181 7.27854 8.92993 7.16985 8.9378C7.06116 8.94567 6.95243 8.92298 6.85597 8.87228L4.86803 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M19.7635 7.83029L17.7756 8.87228C17.6791 8.92298 17.5704 8.94567 17.4617 8.9378C17.353 8.92993 17.2487 8.89181 17.1606 8.82775C17.0724 8.76369 17.0039 8.67624 16.9629 8.57529C16.9218 8.47434 16.9098 8.36392 16.9283 8.25652L17.3082 6.04303L15.7002 4.47446C15.622 4.39843 15.5667 4.30204 15.5405 4.19622C15.5144 4.09039 15.5183 3.97934 15.5519 3.87564C15.5856 3.77194 15.6476 3.67973 15.7309 3.60944C15.8143 3.53915 15.9156 3.49359 16.0235 3.47791L18.2464 3.15545L19.2404 1.14093C19.2887 1.04328 19.3633 0.961081 19.4558 0.903606C19.5483 0.846132 19.655 0.815674 19.764 0.815674C19.8729 0.815674 19.9796 0.846132 20.0722 0.903606C20.1647 0.961081 20.2393 1.04328 20.2875 1.14093L21.2806 3.15545L23.5036 3.47791C23.6115 3.49359 23.7128 3.53915 23.7961 3.60944C23.8795 3.67973 23.9415 3.77194 23.9751 3.87564C24.0088 3.97934 24.0127 4.09039 23.9865 4.19622C23.9603 4.30204 23.905 4.39843 23.8269 4.47446L22.2189 6.04303L22.5988 8.25652C22.6172 8.36392 22.6052 8.47434 22.5642 8.57529C22.5231 8.67624 22.4547 8.76369 22.3665 8.82775C22.2784 8.89181 22.174 8.92993 22.0654 8.9378C21.9567 8.94567 21.8479 8.92298 21.7515 8.87228L19.7635 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M34.66 7.83029L32.6721 8.87228C32.5756 8.92298 32.4669 8.94567 32.3582 8.9378C32.2495 8.92993 32.1452 8.89181 32.057 8.82775C31.9689 8.76369 31.9004 8.67624 31.8594 8.57529C31.8183 8.47434 31.8063 8.36392 31.8248 8.25652L32.2047 6.04303L30.5967 4.47446C30.5185 4.39843 30.4632 4.30204 30.437 4.19622C30.4108 4.09039 30.4148 3.97934 30.4484 3.87564C30.4821 3.77194 30.5441 3.67973 30.6274 3.60944C30.7107 3.53915 30.8121 3.49359 30.92 3.47791L33.1429 3.15545L34.1369 1.14093C34.1851 1.04328 34.2597 0.961081 34.3523 0.903606C34.4448 0.846132 34.5515 0.815674 34.6604 0.815674C34.7694 0.815674 34.8761 0.846132 34.9686 0.903606C35.0612 0.961081 35.1358 1.04328 35.184 1.14093L36.1771 3.15545L38.4001 3.47791C38.5079 3.49359 38.6093 3.53915 38.6926 3.60944C38.776 3.67973 38.838 3.77194 38.8716 3.87564C38.9053 3.97934 38.9092 4.09039 38.883 4.19622C38.8568 4.30204 38.8015 4.39843 38.7234 4.47446L37.1154 6.04303L37.4953 8.25652C37.5137 8.36392 37.5017 8.47434 37.4607 8.57529C37.4196 8.67624 37.3512 8.76369 37.263 8.82775C37.1748 8.89181 37.0705 8.92993 36.9618 8.9378C36.8531 8.94567 36.7444 8.92298 36.648 8.87228L34.66 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M49.5555 7.83029L47.5676 8.87228C47.4711 8.92298 47.3624 8.94567 47.2537 8.9378C47.145 8.92993 47.0407 8.89181 46.9525 8.82775C46.8644 8.76369 46.7959 8.67624 46.7549 8.57529C46.7138 8.47434 46.7018 8.36392 46.7203 8.25652L47.1002 6.04303L45.4922 4.47446C45.414 4.39843 45.3587 4.30204 45.3325 4.19622C45.3063 4.09039 45.3103 3.97934 45.3439 3.87564C45.3776 3.77194 45.4396 3.67973 45.5229 3.60944C45.6063 3.53915 45.7076 3.49359 45.8155 3.47791L48.0384 3.15545L49.0324 1.14093C49.0806 1.04328 49.1553 0.961081 49.2478 0.903606C49.3403 0.846132 49.447 0.815674 49.556 0.815674C49.6649 0.815674 49.7716 0.846132 49.8641 0.903606C49.9567 0.961081 50.0313 1.04328 50.0795 1.14093L51.0726 3.15545L53.2956 3.47791C53.4035 3.49359 53.5048 3.53915 53.5881 3.60944C53.6715 3.67973 53.7335 3.77194 53.7671 3.87564C53.8008 3.97934 53.8047 4.09039 53.7785 4.19622C53.7523 4.30204 53.697 4.39843 53.6189 4.47446L52.0109 6.04303L52.3908 8.25652C52.4092 8.36392 52.3972 8.47434 52.3562 8.57529C52.3151 8.67624 52.2467 8.76369 52.1585 8.82775C52.0704 8.89181 51.966 8.92993 51.8573 8.9378C51.7487 8.94567 51.6399 8.92298 51.5435 8.87228L49.5555 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M64.452 7.83029L62.4641 8.87228C62.3676 8.92298 62.2589 8.94567 62.1502 8.9378C62.0415 8.92993 61.9372 8.89181 61.849 8.82775C61.7609 8.76369 61.6924 8.67624 61.6514 8.57529C61.6103 8.47434 61.5983 8.36392 61.6168 8.25652L61.9967 6.04303L60.3887 4.47446C60.3105 4.39843 60.2552 4.30204 60.229 4.19622C60.2028 4.09039 60.2068 3.97934 60.2404 3.87564C60.2741 3.77194 60.3361 3.67973 60.4194 3.60944C60.5027 3.53915 60.6041 3.49359 60.712 3.47791L62.9349 3.15545L63.9289 1.14093C63.9771 1.04328 64.0517 0.961081 64.1443 0.903606C64.2368 0.846132 64.3435 0.815674 64.4524 0.815674C64.5614 0.815674 64.6681 0.846132 64.7606 0.903606C64.8531 0.961081 64.9278 1.04328 64.976 1.14093L65.9691 3.15545L68.1921 3.47791C68.2999 3.49359 68.4013 3.53915 68.4846 3.60944C68.568 3.67973 68.63 3.77194 68.6636 3.87564C68.6973 3.97934 68.7012 4.09039 68.675 4.19622C68.6488 4.30204 68.5935 4.39843 68.5154 4.47446L66.9074 6.04303L67.2873 8.25652C67.3057 8.36392 67.2937 8.47434 67.2527 8.57529C67.2116 8.67624 67.1431 8.76369 67.055 8.82775C66.9668 8.89181 66.8625 8.92993 66.7538 8.9378C66.6451 8.94567 66.5364 8.92298 66.44 8.87228L64.452 7.83029Z"
                        fill="#E5DDD5"
                      />
                    </svg>
                    <p className="text-[#333333] font-normal text-[8px]">
                      4.1 <span>(43)</span>
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <div className={`${styles.previewInputImage} `}>
                  <img
                    src={pizza}
                    alt="1"
                    className={`w-[250px] h-[140px] object-cover rounded-[25px] mb-3 ${styles.sugImage}`}
                  />
                </div>
                <div className="flex items-center flex-col">
                  <div className={styles.previewAdDetails}>
                    <div
                      className={`${styles.adPreviewTitle} flex items-center justify-center relative right-7`}
                    >
                      <p className="mb-1">Pizzeria da Pepe</p>
                    </div>

                    <div className={styles.locations}>
                      <svg
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.7127 0.540527C4.37909 0.546671 3.10244 1.0671 2.16284 1.98764C1.22323 2.90818 0.697397 4.15365 0.700699 5.45081C0.700699 9.29788 5.24205 13.2712 5.43489 13.4365C5.51153 13.5025 5.61034 13.539 5.7127 13.539C5.81505 13.539 5.91387 13.5025 5.99051 13.4365C6.18144 13.2694 10.7247 9.29696 10.7247 5.45081C10.728 4.15365 10.2022 2.90818 9.26256 1.98764C8.32295 1.0671 7.04631 0.546671 5.7127 0.540527ZM5.7127 8.12417C5.16193 8.12417 4.62352 7.96531 4.16557 7.66768C3.70762 7.37005 3.35069 6.94702 3.13991 6.45208C2.92914 5.95714 2.87399 5.41252 2.98145 4.8871C3.0889 4.36167 3.35412 3.87904 3.74357 3.50023C4.13303 3.12142 4.62923 2.86344 5.16942 2.75893C5.70961 2.65442 6.26953 2.70806 6.77838 2.91307C7.28723 3.11808 7.72215 3.46525 8.02815 3.91069C8.33414 4.35612 8.49746 4.87981 8.49746 5.41553C8.4967 6.13368 8.20307 6.8222 7.68099 7.33001C7.15891 7.83782 6.45103 8.12343 5.7127 8.12417Z"
                          fill="#333333"
                        />
                      </svg>

                      <p>2km</p>
                    </div>
                  </div>
                  {/* <img src={stars} alt="location1" className={styles.stars} /> */}
                  <div
                    className={`flex items-center gap-1 relative right-[54px] ${styles.star}`}
                  >
                    <svg
                      width="69"
                      height="9"
                      viewBox="0 0 69 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.86803 7.83029L2.88009 8.87228C2.78362 8.92298 2.6749 8.94567 2.56621 8.9378C2.45752 8.92993 2.3532 8.89181 2.26505 8.82775C2.17689 8.76369 2.10842 8.67624 2.06737 8.57529C2.02632 8.47434 2.01434 8.36392 2.03277 8.25652L2.41269 6.04303L0.804671 4.47446C0.726535 4.39843 0.671239 4.30204 0.645042 4.19622C0.618845 4.09039 0.622792 3.97934 0.656436 3.87564C0.690081 3.77194 0.75208 3.67973 0.835417 3.60944C0.918754 3.53915 1.0201 3.49359 1.12799 3.47791L3.35092 3.15545L4.34489 1.14093C4.39315 1.04328 4.46775 0.961081 4.56027 0.903606C4.65279 0.846132 4.75954 0.815674 4.86846 0.815674C4.97738 0.815674 5.08412 0.846132 5.17664 0.903606C5.26916 0.961081 5.34377 1.04328 5.39203 1.14093L6.38514 3.15545L8.60807 3.47791C8.71595 3.49359 8.8173 3.53915 8.90064 3.60944C8.98398 3.67973 9.04598 3.77194 9.07962 3.87564C9.11327 3.97934 9.11721 4.09039 9.09101 4.19622C9.06482 4.30204 9.00952 4.39843 8.93139 4.47446L7.32337 6.04303L7.70329 8.25652C7.72172 8.36392 7.70973 8.47434 7.66868 8.57529C7.62764 8.67624 7.55916 8.76369 7.47101 8.82775C7.38286 8.89181 7.27854 8.92993 7.16985 8.9378C7.06116 8.94567 6.95243 8.92298 6.85597 8.87228L4.86803 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M19.7635 7.83029L17.7756 8.87228C17.6791 8.92298 17.5704 8.94567 17.4617 8.9378C17.353 8.92993 17.2487 8.89181 17.1606 8.82775C17.0724 8.76369 17.0039 8.67624 16.9629 8.57529C16.9218 8.47434 16.9098 8.36392 16.9283 8.25652L17.3082 6.04303L15.7002 4.47446C15.622 4.39843 15.5667 4.30204 15.5405 4.19622C15.5144 4.09039 15.5183 3.97934 15.5519 3.87564C15.5856 3.77194 15.6476 3.67973 15.7309 3.60944C15.8143 3.53915 15.9156 3.49359 16.0235 3.47791L18.2464 3.15545L19.2404 1.14093C19.2887 1.04328 19.3633 0.961081 19.4558 0.903606C19.5483 0.846132 19.655 0.815674 19.764 0.815674C19.8729 0.815674 19.9796 0.846132 20.0722 0.903606C20.1647 0.961081 20.2393 1.04328 20.2875 1.14093L21.2806 3.15545L23.5036 3.47791C23.6115 3.49359 23.7128 3.53915 23.7961 3.60944C23.8795 3.67973 23.9415 3.77194 23.9751 3.87564C24.0088 3.97934 24.0127 4.09039 23.9865 4.19622C23.9603 4.30204 23.905 4.39843 23.8269 4.47446L22.2189 6.04303L22.5988 8.25652C22.6172 8.36392 22.6052 8.47434 22.5642 8.57529C22.5231 8.67624 22.4547 8.76369 22.3665 8.82775C22.2784 8.89181 22.174 8.92993 22.0654 8.9378C21.9567 8.94567 21.8479 8.92298 21.7515 8.87228L19.7635 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M34.66 7.83029L32.6721 8.87228C32.5756 8.92298 32.4669 8.94567 32.3582 8.9378C32.2495 8.92993 32.1452 8.89181 32.057 8.82775C31.9689 8.76369 31.9004 8.67624 31.8594 8.57529C31.8183 8.47434 31.8063 8.36392 31.8248 8.25652L32.2047 6.04303L30.5967 4.47446C30.5185 4.39843 30.4632 4.30204 30.437 4.19622C30.4108 4.09039 30.4148 3.97934 30.4484 3.87564C30.4821 3.77194 30.5441 3.67973 30.6274 3.60944C30.7107 3.53915 30.8121 3.49359 30.92 3.47791L33.1429 3.15545L34.1369 1.14093C34.1851 1.04328 34.2597 0.961081 34.3523 0.903606C34.4448 0.846132 34.5515 0.815674 34.6604 0.815674C34.7694 0.815674 34.8761 0.846132 34.9686 0.903606C35.0612 0.961081 35.1358 1.04328 35.184 1.14093L36.1771 3.15545L38.4001 3.47791C38.5079 3.49359 38.6093 3.53915 38.6926 3.60944C38.776 3.67973 38.838 3.77194 38.8716 3.87564C38.9053 3.97934 38.9092 4.09039 38.883 4.19622C38.8568 4.30204 38.8015 4.39843 38.7234 4.47446L37.1154 6.04303L37.4953 8.25652C37.5137 8.36392 37.5017 8.47434 37.4607 8.57529C37.4196 8.67624 37.3512 8.76369 37.263 8.82775C37.1748 8.89181 37.0705 8.92993 36.9618 8.9378C36.8531 8.94567 36.7444 8.92298 36.648 8.87228L34.66 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M49.5555 7.83029L47.5676 8.87228C47.4711 8.92298 47.3624 8.94567 47.2537 8.9378C47.145 8.92993 47.0407 8.89181 46.9525 8.82775C46.8644 8.76369 46.7959 8.67624 46.7549 8.57529C46.7138 8.47434 46.7018 8.36392 46.7203 8.25652L47.1002 6.04303L45.4922 4.47446C45.414 4.39843 45.3587 4.30204 45.3325 4.19622C45.3063 4.09039 45.3103 3.97934 45.3439 3.87564C45.3776 3.77194 45.4396 3.67973 45.5229 3.60944C45.6063 3.53915 45.7076 3.49359 45.8155 3.47791L48.0384 3.15545L49.0324 1.14093C49.0806 1.04328 49.1553 0.961081 49.2478 0.903606C49.3403 0.846132 49.447 0.815674 49.556 0.815674C49.6649 0.815674 49.7716 0.846132 49.8641 0.903606C49.9567 0.961081 50.0313 1.04328 50.0795 1.14093L51.0726 3.15545L53.2956 3.47791C53.4035 3.49359 53.5048 3.53915 53.5881 3.60944C53.6715 3.67973 53.7335 3.77194 53.7671 3.87564C53.8008 3.97934 53.8047 4.09039 53.7785 4.19622C53.7523 4.30204 53.697 4.39843 53.6189 4.47446L52.0109 6.04303L52.3908 8.25652C52.4092 8.36392 52.3972 8.47434 52.3562 8.57529C52.3151 8.67624 52.2467 8.76369 52.1585 8.82775C52.0704 8.89181 51.966 8.92993 51.8573 8.9378C51.7487 8.94567 51.6399 8.92298 51.5435 8.87228L49.5555 7.83029Z"
                        fill="#8CC9E9"
                      />
                      <path
                        d="M64.452 7.83029L62.4641 8.87228C62.3676 8.92298 62.2589 8.94567 62.1502 8.9378C62.0415 8.92993 61.9372 8.89181 61.849 8.82775C61.7609 8.76369 61.6924 8.67624 61.6514 8.57529C61.6103 8.47434 61.5983 8.36392 61.6168 8.25652L61.9967 6.04303L60.3887 4.47446C60.3105 4.39843 60.2552 4.30204 60.229 4.19622C60.2028 4.09039 60.2068 3.97934 60.2404 3.87564C60.2741 3.77194 60.3361 3.67973 60.4194 3.60944C60.5027 3.53915 60.6041 3.49359 60.712 3.47791L62.9349 3.15545L63.9289 1.14093C63.9771 1.04328 64.0517 0.961081 64.1443 0.903606C64.2368 0.846132 64.3435 0.815674 64.4524 0.815674C64.5614 0.815674 64.6681 0.846132 64.7606 0.903606C64.8531 0.961081 64.9278 1.04328 64.976 1.14093L65.9691 3.15545L68.1921 3.47791C68.2999 3.49359 68.4013 3.53915 68.4846 3.60944C68.568 3.67973 68.63 3.77194 68.6636 3.87564C68.6973 3.97934 68.7012 4.09039 68.675 4.19622C68.6488 4.30204 68.5935 4.39843 68.5154 4.47446L66.9074 6.04303L67.2873 8.25652C67.3057 8.36392 67.2937 8.47434 67.2527 8.57529C67.2116 8.67624 67.1431 8.76369 67.055 8.82775C66.9668 8.89181 66.8625 8.92993 66.7538 8.9378C66.6451 8.94567 66.5364 8.92298 66.44 8.87228L64.452 7.83029Z"
                        fill="#E5DDD5"
                      />
                    </svg>
                    <p className="text-[#333333] font-normal text-[8px]">
                      4.1 <span>(43)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {adData.adType === "Feed" && (
          <div className={styles.imageContainer1}>
            <img
              src={inFeedList}
              alt="inFeedList"
              className={styles.suggestionImage1}
            />
            <div className={styles.imageInside1}>
              <div>
                <div className={styles.previewInputImage1}>
                  <div className={styles.div1}>
                    <img
                      src={Ad}
                      alt="1"
                      style={{
                        width: "30px",
                        position: "absolute",
                        top: "4%",
                        right: "95%",
                      }}
                    />
                    <img
                      src={Cross}
                      alt="1"
                      style={{
                        width: "30px",
                        position: "absolute",
                        left: "95%",
                        top: "4%",
                      }}
                    />
                    <img
                      src={adData.image || pizza}
                      alt="1"
                      className="rounded-[25px] object-cover h-[150px] "
                    />

                    <div className={styles.div2}>
                      <p className={styles.paragraph}>{adData.title}</p>
                      <p className={styles.paragraph1}>{adData.body}</p>
                    </div>
                    <div className={styles.div3}>
                      <button className={styles.buttons1}>
                        All Localities
                      </button>
                      <button className={styles.buttons2}>More Details</button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}></div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RequestedAds;
