import { useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import "./App.css";
import Router from "./Components/Router";
import Home from "./Pages/Home/Home";
import "./tailwind.css";
function App() {
  const location = useLocation();
  if (location.pathname === "/" || location.pathname === "/login") {
    return (
      <div className="App">
        <Router />
      </div>
    );
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#8CC9E9",
          borderRadius: 2,

          // Alias Token
          // colorBgContainer: "#f6ffed",
        },
      }}
    >
      <div className="App">
        <Home />
      </div>
    </ConfigProvider>
  );
}

export default App;
