import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import styles from "./../style.module.css";
import crossImg from "../../../../assets/crossImg.svg";
import {
  fetchPartnerAdData,
  deletePartnerAdData,
  updatePartnerAdData,
} from "../../../../Redux/Advertisement/Partner/PartnerAdReducer";
import { message } from "antd";
import { useDispatch } from "react-redux";
import ReasonIcon from "./ReasonIcon";
import { RxCrossCircled } from "react-icons/rx";

const ReasonModel = ({
  el,
  type,
  search,
  page,
  partnerAd,
  adType,
  releaseStartDate,
  releaseEndDate,
  createdStartDate,
  createdEndDate,
  title,
}) => {
  console.log("el", el);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const partnerId = JSON.parse(localStorage.getItem("userId"));
  const handleYes = () => {
    const data = {
      adId: el._id,
      partnerId: el.partnerId._id,
      status: "Rejected",
      deleted: true,
    };
    if (partnerAd) {
      dispatch(updatePartnerAdData(data)).then(() => {
        dispatch(
          fetchPartnerAdData(
            (type = "Overview"),
            (search = ""),
            (page = "1"),
            (adType = ""),
            (releaseStartDate = ""),
            (releaseEndDate = ""),
            (createdStartDate = ""),
            (createdEndDate = ""),
            partnerId
          )
        ).then(() => {
          onClose();
          message.success("Advertisement successfully delete");
        });
      });
    }
  };
  return (
    <>
      <div onClick={onOpen}>
        {title === "reason" ? (
          <ReasonIcon />
        ) : (
          <p className={`flex gap-2 ${styles.blackBtn31}`}>
            <RxCrossCircled fontSize={20} /> Cancel
          </p>
        )}
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {title === "reason" ? (
              <p className="py-10 px-4">
                <span className="font-semibold">Reason: </span>
                {el?.reason}
              </p>
            ) : (
              <div className={styles.deleteModeldiv}>
                <img src={crossImg} alt="" />

                <p>
                  Move this Advertisement to the
                  <br />
                  History tab?
                </p>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {title === "reason" ? (
              <></>
            ) : (
              <div className={styles.modelFooter}>
                <Button
                  variant="solid"
                  color="black"
                  mr={3}
                  onClick={handleYes}
                >
                  Yes
                </Button>

                <Button variant="outline" color="black" onClick={onClose}>
                  No
                </Button>
              </div>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReasonModel;
