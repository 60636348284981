import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import location from "../../../assets/location1.png";
import AdChart from "./AdChart";
import { useParams, Link } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import { message } from "antd";

function ViewAdStats() {
  const { id } = useParams();

  const [adData, setAdData] = useState([]);

  useEffect(() => {
    const fetchSingleAd = async () => {
      try {
        const response = await userRequest.get(
          `/partner/ads/getSingleAds?_id=${id}`
        );
        const singleAd = response.data.data;
        setAdData(singleAd);
      } catch (error) {
        message.error("Error fetching single ad:", error.message);
      }
    };
    fetchSingleAd();
  }, [id]);

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headDiv}></div>
      <div className={styles.breadCrumb}>
        <p className="font-bold text-xl md:text-base px-5 mt-28 md:mt-0 md:px-0">
          <Link
            to="/Partner"
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Advertisement
          </Link>{" "}
          <span className="mx-2">&gt; </span>
          <span> Ad Stats</span>
        </p>
      </div>
      <div className={`${styles.adDetails} px-5 md:px-0`}>
        <p>{adData?.title}</p>
        <p>{adData?.name}</p>
        <div className={styles.location}>
          <img src={location} alt="" /> <p>{adData?.cities?.[0]}</p>
        </div>
        <div className={styles.adContent}>{adData?.body}</div>
        <div className={styles.adInfo}>
          <div className={styles.adType}>
            <p>Ad Type</p>
            <p>{adData?.adType}</p>
          </div>
          <div className={styles.adType}>
            <p>Released On</p>
            <p>{convertDate(adData?.releaseDate)}</p>
          </div>
        </div>
      </div>
      <div className={`${styles.chart} px-5 md:px-0`}>
        <AdChart releaseTime={adData?.releaseTime} />
      </div>
    </div>
  );
}

export default ViewAdStats;

function convertDate(dateStr) {
  try {
    const dateObj = new Date(dateStr);
    const formattedDate = `${dateObj.getUTCDate()}.${
      dateObj.getUTCMonth() + 1
    }.${dateObj.getUTCFullYear()}`;
    return formattedDate;
  } catch (error) {
    return null;
  }
}
