import { createSlice } from "@reduxjs/toolkit";
import { userRequest } from "../../requestMethod";

const initialState = {
  Notification: [], // Change 'partnerAd' to 'Notification'
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: "Notification", // Change 'partnerAd' to 'Notification'
  initialState,
  reducers: {
    getNotificationDataRequest: (state) => {
      // Change 'getPartnerAdDataRequest' to 'getNotificationDataRequest'
      state.isLoading = true;
    },
    getNotificationDataSuccess: (state, action) => {
      // Change 'getPartnerAdDataSuccess' to 'getNotificationDataSuccess'
      state.Notification = action.payload; // Change 'partnerAd' to 'Notification'
      state.isLoading = false;
      state.error = null;
    },
    getNotificationDataFailure: (state, action) => {
      // Change 'getPartnerAdDataFailure' to 'getNotificationDataFailure'
      state.Notification = []; // Change 'partnerAd' to 'Notification'
      state.isLoading = false;
      state.error = action.payload;
    },
    createNotificationDataRequest: (state) => {
      // Change 'createPartnerAdDataRequest' to 'createNotificationDataRequest'
      state.isLoading = true;
    },
    createNotificationDataSuccess: (state, action) => {
      // Change 'createPartnerAdDataSuccess' to 'createNotificationDataSuccess'
      state.Notification = action.payload; // Change 'partnerAd' to 'Notification'
      state.isLoading = false;
      state.error = null;
    },
    createNotificationDataFailure: (state, action) => {
      // Change 'createPartnerAdDataFailure' to 'createNotificationDataFailure'
      state.isLoading = false;
      state.error = action.payload;
    },
    updateNotificationDataRequest: (state) => {
      // Change 'updatePartnerAdDataRequest' to 'updateNotificationDataRequest'
      state.isLoading = true;
    },
    updateNotificationDataSuccess: (state, action) => {
      // Change 'updatePartnerAdDataSuccess' to 'updateNotificationDataSuccess'
      state.Notification = []; // Change 'partnerAd' to 'Notification'
      state.isLoading = false;
      state.error = null;
    },
    updateNotificationDataFailure: (state, action) => {
      // Change 'updatePartnerAdDataFailure' to 'updateNotificationDataFailure'
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getNotificationDataRequest,
  getNotificationDataSuccess,
  getNotificationDataFailure,
  createNotificationDataRequest,
  createNotificationDataSuccess,
  createNotificationDataFailure,
  updateNotificationDataRequest,
  updateNotificationDataSuccess,
  updateNotificationDataFailure,
} = userSlice.actions;

export default userSlice.reducer;
const partnerId = JSON.parse(localStorage.getItem("userId"));
export const fetchNotificationData =
  (
    type,
    search = "",
    page,
    releasStartDate = "",
    releaseEndDate = "",
    city = "",
    partnerId
  ) =>
  async (dispatch) => {
    try {
      dispatch(getNotificationDataRequest());

      const url =
        `/api/notification/getAllNotifications?page=${page}&limit=10&search=${search}&status=${type}&partnerId=${partnerId}&userType=PARTNER` +
        `${releasStartDate ? `&releasStartDate=${releasStartDate}` : ""}` +
        `${releaseEndDate ? `&releaseEndDate=${releaseEndDate}` : ""}` +
        `${city ? `&city=${city}` : ""}`;

      const response = await userRequest.get(url);
      dispatch(getNotificationDataSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getNotificationDataFailure(error.message));
      return error.message;
    }
  };

export const createNotificationData =
  (data, type, search, page, releasStartDate, releaseEndDate, city) =>
  async (dispatch) => {
    try {
      dispatch(createNotificationDataRequest());

      const newData = { ...data, status: "Pending" };

      const res = await userRequest.post(
        "/api/notification/createNotifications",
        newData
      );
      dispatch(createNotificationDataSuccess(res.data));
      dispatch(
        fetchNotificationData(
          (type = "Pending"),
          search,
          (page = "1"),
          releasStartDate,
          releaseEndDate,
          city,
          partnerId
        )
      );

      return res.data;
    } catch (error) {
      dispatch(createNotificationDataFailure(error.message));
      return error.message;
    }
  };

export const updateNotificationData = (data) => async (dispatch) => {
  console.log("data", data);
  try {
    dispatch(updateNotificationDataRequest());
    const res = await userRequest.delete(
      `/api/notification/deleteNotification/${data?.notificationId}`
    );
    dispatch(updateNotificationDataSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(updateNotificationDataFailure(error.message));
    return error.message;
  }
};
