import React from "react";
import styles from "../style.module.css";
import { Avatar } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase_config";
import messageFav from "../../../assets/messageFav.png";
const Inbox = ({ inboxArray }) => {
  const navigate = useNavigate();

  function formatCustomDate(timestamp) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dateObjUTC = new Date(timestamp);
    const dateObjIST = new Date(dateObjUTC.getTime() + 5.5 * 60 * 60 * 1000); // Adding 5 hours 30 minutes for IST
    const year = dateObjIST.getUTCFullYear();
    const month = months[dateObjIST.getUTCMonth()];
    const day = dateObjIST.getUTCDate();
    const hours = dateObjIST.getUTCHours();
    const minutes = dateObjIST.getUTCMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const daySuffix = getDaySuffix(day);
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedDate = `${day}${daySuffix} ${month}, ${year} | ${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedDate;
  }

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
  async function updateMessageSeen(outboxId, chatId) {
    const outboxChatUserRef = collection(db, "inbox", outboxId, "chatUser");

    try {
      const querySnapshot = await getDocs(outboxChatUserRef);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.id === chatId) {
          const chatDocRef = doc.ref;
          updateDoc(chatDocRef, {
            receiver: true,
          })
            .then(() => {
              console.log("Message updated to msgSeen: true");
            })
            .catch((error) => {
              console.error("Error updating message:", error);
            });
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  if (inboxArray.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }
  function sortMessages(messages) {
    const unseenMessages = messages.filter((message) => !message.receiver);
    const seenMessages = messages.filter((message) => message.receiver);

    return [...unseenMessages, ...seenMessages];
  }
  return (
    <div className={styles.tableDiv}>
      <table
        className={`flex auto border-2 border-gray-200 overflow-auto ${styles.table}`}
      >
        <div
          className={`w-full ${styles.tableWrap} overflow-auto md:max-h-[37vw]`}
        >
          {" "}
          <tr className=" w-[160%]  md:w-full flex relative z-10 items-center justify-center md:justify-start">
            <th className="w-[25%] md:w-[25%]">From</th>
            <th className="w-[25%] md:w-[25%]">Subject</th>
            <th className="w-[25%] md:w-[25%]">Message Body</th>
            <th className="w-[25%] md:w-[25%]">Date & Time</th>
          </tr>
          {sortMessages(inboxArray).map((el, i) => {
            console.log("messages", el);
            return (
              <tr
                key={i}
                onClick={() => {
                  updateMessageSeen(el.to, el.id).then(() => {
                    navigate(
                      `/Messages/Chat/${el.from}?inbox=true&chatId=${el.id}`
                    );
                  });
                }}
                className={`h-[60px] md:h-auto min-h-[60px] cursor-pointer hover:bg-gray-100 transition-all delay-75 ease-out border-t border-gray-200 flex w-[160%]  md:w-full items-center ${
                  !el.receiver && styles.unseen
                }`}
              >
                {/* <td className="w-[25%] md:w-[25%] mt-2 truncate">
                  <div className="w-[45px] h-[45px] rounded-full  bg-[#B9DEF1] absolute top-[50%] left-[50%] md:left-[18%] translate-x-[-50%] translate-y-[-50%]">
                    <p className="text-2xl text-[#2C6E8F] font-semibold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                      N
                    </p>
                  </div>
                </td> */}
                <td className="w-[25%] md:w-[25%] mt-2">
                  <img
                    className="w-10 h-10"
                    src={messageFav}
                    alt="messageFav"
                  />
                </td>
                <td className="w-[25%] md:w-[25%] mt-2 truncate">
                  {" "}
                  {el?.subject}
                </td>
                <td className="w-[25%] md:w-[25%] mt-2 truncate">{el?.Msg}</td>
                <td className="w-[25%] md:w-[25%] mt-2">
                  {formatCustomDate(el?.timestamp)}
                </td>
              </tr>
            );
          })}
        </div>
      </table>
    </div>
  );
};

export default Inbox;
