import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
import Upcoming from "./Upcoming/Upcoming";
import Request from "./Requests/Request";
import Previous from "./Previous/Previous";
import { Helmet } from "react-helmet";
import CreateNotification from "./CreateNotification/CreateNotification";
import ContactPartner from "./ContactPartner/CreateMassage/ContactPartner";
import { useDispatch, useSelector } from "react-redux";
import AdFilter from "./Filter/AdFilter";
import { fetchNotificationData } from "../../Redux/PushNotification/NotificationReducer";

const PushNotification = () => {
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const initialTab =
  //   params.get("tab") === "history"
  //     ? "History"
  //     : params.get("tab") === "pending"
  //     ? "Pending"
  //     : "Upcoming";
  const [create, setCreate] = useState(false);
  const [contact, setContact] = useState(false);
  const [type, setType] = useState("Upcoming");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [releasStartDate, setReleasStartDate] = useState("");
  const [releaseEndDate, setReleaseEndDate] = useState("");
  const [city, setCity] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const dispatch = useDispatch();
  const notification =
    useSelector((store) => store.NotificationReducer.Notification.data) || [];
  const { pendingCount, historyCount, overViewCount } = useSelector(
    (store) => store.NotificationReducer.Notification
  );
  const loading = useSelector((store) => store?.NotificationReducer?.isLoading);
  const partnerId = JSON.parse(localStorage.getItem("userId"));
  useEffect(() => {
    if (partnerId) {
      dispatch(
        fetchNotificationData(
          type,
          search,
          page,
          releasStartDate,
          releaseEndDate,
          city,
          partnerId
        )
      );
    } else {
      console.error("partnerId is null or undefined");
    }
  }, [
    page,
    search,
    type,
    releasStartDate,
    releaseEndDate,
    city,
    dispatch,
    partnerId,
  ]);

  if (create) {
    return (
      <CreateNotification
        setCreate={setCreate}
        setType={setType}
        type={type}
        search={search}
        page={page}
      />
    );
  }
  if (contact) {
    return <ContactPartner setContact={setContact} />;
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.spin}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
        </div>
      );
    }

    switch (type) {
      case "Upcoming":
        return <Upcoming notification={notification} />;
      case "Pending":
        return (
          <Request
            notification={notification}
            setContact={setContact}
            setCreate={setCreate}
          />
        );
      case "History":
        return <Previous notification={notification} loading={loading} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.MainContainer}>
      <Helmet>
        <title> Push Notification - NETME</title>
      </Helmet>
      <div className={styles.firstDiv}>
        <h1 className="hidden text-3xl font-bold md:block md:pt-5 md:pr-5 lg:pt-0 lg:pr-0">
          Push Notification
        </h1>

        <div className={styles.filterDiv}>
          <div className="hidden md:block">
            <InputGroup w={200}>
              <Input
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputRightElement>
                <BiSearch color="green.500" />
              </InputRightElement>
            </InputGroup>
          </div>
          <AdFilter
            releasStartDate={releasStartDate}
            releaseEndDate={releaseEndDate}
            city={city}
            setCity={setCity}
            setReleasStartDate={setReleasStartDate}
            setReleaseEndDate={setReleaseEndDate}
            setFilterApplied={setFilterApplied}
            filterApplied={filterApplied}
            search={search}
            type={type}
            page={page}
            partnerId={partnerId}
          />
          <div className="hidden md:block">
            <Button bg="#8CC9E9" onClick={() => setCreate(true)}>
              Create New Notification
            </Button>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-0">
        <h1 className="pt-28 text-3xl font-bold md:hidden">
          {" "}
          Push Notification
        </h1>
        <div className="flex flex-col sm:flex-row md:hidden my-5 gap-4 sm:gap-10">
          <InputGroup w={210}>
            <Input
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement>
              <BiSearch color="green.500" />
            </InputRightElement>
          </InputGroup>
          <div className="block md:hidden">
            <Button bg="#8CC9E9" onClick={() => setCreate(true)}>
              Create New Notification
            </Button>
          </div>
        </div>
        <Tabs
          index={type === "Upcoming" ? 0 : type === "Pending" ? 1 : 2}
          onChange={(index) =>
            setType(
              index === 0 ? "Upcoming" : index === 1 ? "Pending" : "History"
            )
          }
        >
          <div className="relative">
            <TabList>
              <Tab className={styles.tabPanel}>
                Upcoming{" "}
                <span
                  id={type === "Upcoming" ? styles.activeTab : null}
                  className={styles.numberSpan}
                >
                  {overViewCount}
                </span>
              </Tab>
              &nbsp; &nbsp; &nbsp;
              <Tab className={styles.tabPanel}>
                Pending{" "}
                <span
                  id={type === "Pending" ? styles.activeTab : null}
                  className={styles.numberSpan}
                >
                  {pendingCount}
                </span>
              </Tab>
              &nbsp; &nbsp; &nbsp;
              <div className="absolute top-20 left-[50%] translate-x-[-50%] sm1:relative sm1:top-auto sm1:left-auto sm1:translate-x-0">
                <Tab className={styles.tabPanel}>
                  Previous{" "}
                  <span
                    id={type === "History" ? styles.activeTab : null}
                    className={styles.numberSpan}
                  >
                    {historyCount}
                  </span>
                </Tab>
              </div>
            </TabList>
          </div>

          <TabPanels>
            <TabPanel>{type === "Upcoming" && renderContent()}</TabPanel>
            <TabPanel>{type === "Pending" && renderContent()}</TabPanel>
            <TabPanel>{type === "History" && renderContent()}</TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default PushNotification;
