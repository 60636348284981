import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import {
  AiOutlineShareAlt,
  AiOutlineWhatsApp,
  AiOutlineMail,
  AiOutlineTwitter,
} from "react-icons/ai";

const ShareModal = ({ isOpen, onClose }) => {
  const qrCodeValue = `https://i.ibb.co/Z2ZJhjK/qrcode.png`;
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      `Check out this QR code: ${qrCodeValue}`
    );
    window.open(`https://wa.me/?text=${message}`, "_blank");
  };
  const handleTwitterClick = () => {
    const message = encodeURIComponent(
      `Check out this QR code: ${qrCodeValue}`
    );
    window.open(`https://twitter.com/intent/tweet?text=${message}`, "_blank");
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share Your QR Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" justifyContent="space-around">
          <IconButton
            as="button"
            onClick={handleWhatsAppClick}
            aria-label="WhatsApp"
            icon={<AiOutlineWhatsApp />}
            size="lg"
            className="cursor-pointer"
          />
          <IconButton
            as="a"
            href={`mailto:?subject=Your QR Code&body=Hey,%0D%0A%0D%0A You can use this QR code for NETME User to scan and collect stamps:%0D%0A%0D%0A${encodeURIComponent(
              qrCodeValue
            )}`}
            target="_blank"
            aria-label="Email"
            icon={<AiOutlineMail />}
            size="lg"
          />
          <IconButton
            as="button"
            onClick={handleTwitterClick}
            aria-label="Twitter"
            icon={<AiOutlineTwitter />}
            size="lg"
            className="cursor-pointer"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
