import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { userRequest } from "../../../requestMethod";

const LocationPicker = ({ addSelectedCity }) => {
  const [value, setValue] = useState(null);
  const [fontSize, setFontSize] = useState("0.9rem");
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const userId = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setFontSize("16px");
      } else {
        setFontSize("0.9rem");
      }
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const getData = () => {
    userRequest
      .get(`/partner/business/getBusiness?_id=${userId}`)
      .then((res) => {
        const fetchedData = res.data.data;
        setData(fetchedData);
        console.log("Fetched Data: ", fetchedData);

        if (fetchedData.city) {
          const selectedCity = {
            label: `${fetchedData.city} +10km Radius`,
            value: { description: fetchedData.city },
          };
          setValue(selectedCity);
          addSelectedCity(selectedCity); // Call addSelectedCity here
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "20vw",
      height: "70px",
      borderRadius: "18px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      border: "none",
      boxShadow: "none",
      backgroundColor: "#ecf1f4",
      pointerEvents: "none", // Make the input read-only

      "@media (max-width: 769px)": {
        width: "90vw",
      },
    }),
    menu: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    input: (provided) => ({
      ...provided,
      cursor: "not-allowed", // Change cursor to indicate it's not editable
    }),
  };

  return (
    <div>
      {error && (
        <p style={{ color: "red" }} className="text-sm">
          {error}
        </p>
      )}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAP_KEY}
        selectProps={{
          value,
          onChange: (newValue) => {
            setValue(newValue);
            addSelectedCity(newValue);
          },
          styles: customStyles,
          placeholder: "Search Address",
        }}
      />
    </div>
  );
};

export default LocationPicker;
