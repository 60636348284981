import React, { useState } from "react";
import styles from "./styles.module.css";
import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { AiOutlineMail } from "react-icons/ai";
import Otp from "./Otp/Otp";
import ResetPassword from "./ResetPassword/ResetPassword";
import Success from "./Success/Success";
import { message } from "antd";
import { userRequest } from "../../../requestMethod";
import { Link } from "react-router-dom";
const Forget = ({ setForgot }) => {
  const [current, setCurrent] = useState("");
  const [email, setEmail] = useState("");
  const sendOtp = () => {
    if (!email) {
      message.error("Please Enter Email");
    } else {
      userRequest
        .post("/api/util/sendOtp", { email, partner: true })
        .then((res) => {
          setCurrent("OTP");
          message.success(res.data.message);
        })
        .catch((err) => {
          console.log("err:", err?.response?.data?.error);
          message.error(
            err?.response?.data?.error || err?.response?.data?.message
          );
        });
    }
  };
  const hardReload = () => {
    window.location.reload();
  };

  if (current === "OTP") {
    return <Otp setCurrent={setCurrent} email={email} />;
  }
  if (current === "Reset") {
    return <ResetPassword setCurrent={setCurrent} email={email} />;
  }
  if (current === "Success") {
    return <Success setCurrent={setCurrent} setForgot={setForgot} />;
  }
  return (
    <div className={styles.formDiv}>
      <h2>Reset Password</h2>
      <p className={styles.inputText}>Email id</p>
      <InputGroup size="lg">
        <InputLeftElement
          pointerEvents="none"
          children={
            <AiOutlineMail
              color="gray"
              style={{ position: "absolute", left: "15px" }}
            />
          }
        />
        <Input
          type="text"
          placeholder="Enter your email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          focusBorderColor="gray"
        />
      </InputGroup>
      <Link onClick={hardReload}>
        {" "}
        <p className={styles.forgetLink}>Go Back</p>
      </Link>
      <Button onClick={() => sendOtp()}>Send OTP</Button>
    </div>
  );
};

export default Forget;
