import React from "react";
import Message from "./Message";
import styles from "./style.module.css"

const Messages = ({ messages }) => {



  return (
    <div className={styles.messages}>
      {messages.map((m) => (
        <Message message={m} key={m.id} />
      ))}
    </div>
  );
};

export default Messages;
