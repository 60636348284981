import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.css";
import img1 from "../../assets/logo1.svg";
import addLogo from "../../assets/usersDashboard/addIcon.svg";
import addLogoBlack from "../../assets/usersDashboard/analyticsBlack.svg";
import { RxDashboard } from "react-icons/rx";
import { GoPerson } from "react-icons/go";
import { CgNotes } from "react-icons/cg";
import { PiBellRingingBold } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Router from "../../Components/Router";
import { db } from "../../Firebase_config";
import { collection, onSnapshot } from "firebase/firestore";
import { FaBars, FaTimes } from "react-icons/fa";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [outboxArray, setOutbox] = useState([]);
  const [inboxArray, setInbox] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const fetchAndSetMessages = async (collectionRef, setState) => {
    try {
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const messages = snapshot.docs.map((doc) => doc.data());
        const sortedMessages = messages.sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
        setState(sortedMessages);
      });
      return unsubscribe;
    } catch (error) {
      console.error("Error fetching messages: ", error);
    }
  };

  const fetchMessages = async (collectionName, userId, setState) => {
    const collectionRef = collection(db, collectionName, userId, "chatUser");
    const unsubscribe = await fetchAndSetMessages(collectionRef, setState);
    return unsubscribe;
  };

  useEffect(() => {
    fetchMessages("outbox", userId, setOutbox);
    fetchMessages("inbox", userId, setInbox);
  }, [userId]);

  function getInboxCount() {
    const unseenInboxMessages = inboxArray.filter(
      (message) => !message.receiver
    );
    const unseenOutboxMessages = outboxArray.filter(
      (message) => !message.sender
    );
    return unseenInboxMessages.length;
    // return unseenInboxMessages.length + unseenOutboxMessages.length;
  }

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);
  return (
    <div className={styles.mainContainer}>
      <div
        className={`${styles.sideContainer} ${
          isSidebarOpen ? styles.showSidebar : ""
        }`}
      >
        <Link to="/Dashboard" onClick={handleCloseSidebar}>
          <img src={img1} alt="" className={styles.image} />
        </Link>
        <div className={styles.listContainer}>
          <Link to="/Dashboard" onClick={handleCloseSidebar}>
            <div
              id={location === "/Dashboard" ? styles.active : null}
              className="hover:bg-white hover:rounded-xl transition-all delay-[50ms] ease-in"
            >
              <RxDashboard className={styles.sideIcons} /> Dashboard
            </div>
          </Link>
          <Link to="/Profile" onClick={handleCloseSidebar}>
            <div
              id={
                location === "/Profile" || location === "/EditProfile"
                  ? styles.active
                  : null
              }
              className="hover:bg-white hover:rounded-xl transition-all delay-[50ms] ease-in"
            >
              <GoPerson className={styles.sideIcons} /> Profile
            </div>
          </Link>
          <Link to="/Messages" onClick={handleCloseSidebar}>
            <div
              id={
                location === "/Messages" ||
                location === "/CreateMessages" ||
                location.startsWith("/Messages/")
                  ? styles.active
                  : null
              }
              className="hover:bg-white hover:rounded-xl transition-all delay-[50ms] ease-in"
            >
              <span className={styles.msg}>
                <CgNotes className={styles.sideIcons} /> Messages
              </span>
              {getInboxCount() !== 0 && (
                <span className={styles.numberSpan}>{getInboxCount()}</span>
              )}
            </div>
          </Link>
          <Link to="/Notification" onClick={handleCloseSidebar}>
            <div
              id={
                location === "/Notification" ||
                location.startsWith("/Notification/")
                  ? styles.active
                  : null
              }
              className="hover:bg-white hover:rounded-xl transition-all delay-[50ms] ease-in"
            >
              <PiBellRingingBold className={styles.sideIcons} /> Push
              Notification
            </div>
          </Link>
          <Link to="/Partner" onClick={handleCloseSidebar}>
            <div
              id={
                location === "/Partner" || location.startsWith("/Partner/")
                  ? styles.active
                  : null
              }
              className="hover:bg-white hover:rounded-xl transition-all delay-[50ms] ease-in"
            >
              {location === "/Partner" ||
              location === "/External" ||
              location.startsWith("/Partner/") ? (
                <img src={addLogo} alt="" />
              ) : (
                <img src={addLogoBlack} alt="" />
              )}
              Advertisement
            </div>
          </Link>
          {/* <div
            className={`${styles.AddDiv} hover:bg-white hover:rounded-xl transition-all delay-[50ms] ease-in`}
            id={
              location === "/Partner" || location === "/External"
                ? styles.active
                : null
            }
            onClick={() => {
              navigate("/Partner");
              handleCloseSidebar();
            }}
          >
            <div>
              {location === "/Partner" || location === "/External" ? (
                <img src={addLogo} alt="" />
              ) : (
                <img src={addLogoBlack} alt="" />
              )}
              Advertisement
            </div>
          </div> */}
          <Link to="/Settings" onClick={handleCloseSidebar}>
            <div
              id={
                location === "/Settings" || location.startsWith("/colleges/")
                  ? styles.active
                  : null
              }
              className="hover:bg-white hover:rounded-xl transition-all delay-[50ms] ease-in"
            >
              <IoSettingsOutline className={styles.sideIcons} /> Settings
            </div>
          </Link>
        </div>
      </div>
      <div
        className={`${location === "/Dashboard" ? "w-[100%] sm0:w-full" : ""}`}
      >
        <div
          ref={sidebarRef}
          className={`${styles.hamburger} ${
            isSidebarOpen ? styles.active : ""
          }`}
          onClick={handleSidebarToggle}
        >
          <div className={styles.bar}></div>
        </div>
        <Router />
      </div>
    </div>
  );
};

export default Home;
