import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./AuthReducer";
import PartnerAdReducer from "./Advertisement/Partner/PartnerAdReducer";
import NotificationReducer from "./PushNotification/NotificationReducer";

export default configureStore({
  reducer: {
    auth: authReducer,
    PartnerAdReducer: PartnerAdReducer,
    NotificationReducer: NotificationReducer,
  },
});
