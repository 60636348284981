import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  InputRightElement,
  Input,
  Button,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { BiSearch } from "react-icons/bi";
import Inbox from "./Inbox/Inbox";
import Outbox from "./Outbox/Outbox";
import inbox from "../../assets/usersDashboard/in.svg";
import outbox from "../../assets/usersDashboard/out.svg";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../Firebase_config";
import MessageFilter from "./MessageFilter/MessageFilter";

const Massage = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("Inbox");
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [outboxArray, setOutbox] = useState([]);
  const [inboxArray, setInbox] = useState([]);
  const [chatId, setChatId] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [search, setSearch] = useState("");
  const fetchAndSetMessages = async (collectionRef, setState) => {
    try {
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const messages = snapshot.docs
          .map((doc) => doc.data())
          .filter((message) => {
            const timestamp = new Date(message.timestamp).getTime();
            const isWithinDateRange =
              (!startDate || timestamp >= new Date(startDate).getTime()) &&
              (!endDate || timestamp <= new Date(endDate).getTime());
            const matchesSearch =
              !search ||
              message.Msg?.toLowerCase().includes(search.toLowerCase()) ||
              message.subject?.toLowerCase().includes(search.toLowerCase());

            return isWithinDateRange && matchesSearch;
          })
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        setState(messages);
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching messages: ", error);
    }
  };

  const fetchMessages = async (collectionName, userId, setState) => {
    const collectionRef = collection(db, collectionName, userId, "chatUser");
    const unsubscribe = await fetchAndSetMessages(collectionRef, setState);
    return unsubscribe;
  };

  useEffect(() => {
    const initializeMessages = async () => {
      generateChatID();
      const unsubscribeOutbox = await fetchMessages(
        "outbox",
        userId,
        setOutbox
      );
      const unsubscribeInbox = await fetchMessages("inbox", userId, setInbox);

      return () => {
        unsubscribeOutbox && unsubscribeOutbox();
        unsubscribeInbox && unsubscribeInbox();
      };
    };

    initializeMessages();
  }, [filterApplied, search, startDate, endDate]);
  function generateChatID() {
    const timestamp = new Date().getTime(); // Current timestamp in milliseconds
    const randomString = Math.random().toString(36).substr(2, 10); // Random string of length 10

    setChatId(`${timestamp}${randomString}`);
  }

  function getInboxCount(messages) {
    const unseenMessages = messages.filter((message) => message.receiver);
    console.log("unseenMessages", unseenMessages);
    return messages.length;
  }

  function getOutboxCount(messages) {
    return messages.length;
  }

  return (
    <div className={styles.MainContainer}>
      <Helmet>
        <title> Messages - NETME</title>
      </Helmet>
      <div className={styles.firstDiv}>
        <h1 className="hidden text-3xl font-bold md:block md:pt-5 lg:pt-0">
          Messages
        </h1>
        <div className={styles.filterDiv}>
          <div className="hidden md:block">
            <InputGroup w={200}>
              <Input
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputRightElement>
                <BiSearch color="green.500" />
              </InputRightElement>
            </InputGroup>
          </div>
          <MessageFilter
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setFilterApplied={setFilterApplied}
            filterApplied={filterApplied}
          />
          <div className="hidden md:block">
            <Button
              bg="#8CC9E9"
              onClick={() => navigate(`/CreateMessages?chatId=${chatId}`)}
            >
              Create New Message
            </Button>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-0">
        <h1 className="pt-28 text-3xl font-bold md:hidden">Messages</h1>
        <div className="flex flex-col sm:flex-row md:hidden my-5 gap-4 sm:gap-10">
          <InputGroup w={200}>
            <Input
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement>
              <BiSearch color="green.500" />
            </InputRightElement>
          </InputGroup>
          <div className="block md:hidden">
            <Button
              bg="#8CC9E9"
              onClick={() => navigate(`/CreateMessages?chatId=${chatId}`)}
              width={200}
            >
              Create New Message
            </Button>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab
              className={`${styles.tabPanel}`}
              onClick={() => setType("Inbox")}
            >
              {" "}
              <img src={inbox} alt="" /> &nbsp; Inbox{" "}
              <span
                id={type === "Inbox" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {getInboxCount(inboxArray)}
              </span>
            </Tab>
            <span className="hidden md:block">
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
            <span className="block md:hidden">&nbsp; &nbsp;</span>
            <Tab
              className={`${styles.tabPanel}`}
              onClick={() => setType("Outbox")}
            >
              {" "}
              <img src={outbox} alt="" /> &nbsp; Outbox{" "}
              <span
                id={type === "Outbox" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {getOutboxCount(outboxArray)}
              </span>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <div className={styles.tableWrapper}>
                <Inbox inboxArray={inboxArray} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className={styles.tableWrapper}>
                <Outbox outboxArray={outboxArray} />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Massage;
