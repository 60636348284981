import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  ChakraProvider,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Switch,
  Text,
  extendTheme,
} from "@chakra-ui/react";
import { Button } from "antd";
import { message } from "antd";
import { Helmet } from "react-helmet";
import { userRequest } from "../../requestMethod";
import ActivePlan from "./ActivePlan/ActivePlan";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import stripe from "../../assets/stripe.png";
import { AiFillEye, AiFillEyeInvisible, AiFillUnlock } from "react-icons/ai";
import { extractMonthAndYear } from "../../utils/utils";
const initialState = {
  old: "",
  new: "",
  confirm: "",
};
const initialStateEmail = {
  oldEmail: "",
  newEmail: "",
};
const initialStatePayment = {
  cardNumber: "",
  expireDate: "",
  cvv: "",
  postalCode: "",
  method: ["Stripe"],
};
const Settings = () => {
  const [form, setForm] = useState(initialState);
  const [email, setEmail] = useState(initialStateEmail);
  const [state, setState] = useState("reset");
  const [paymentMethod, setPaymentMethod] = useState(initialStatePayment);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedExpireDate, setSelectedExpireDate] = useState();
  const [isNotificationToggled, setIsNotificationToggled] = useState(false);
  const [isEmailToggled, setIsEmailToggled] = useState(false);
  const [show, setShow] = useState(false);
  const [showOld, setShowOld] = useState(false);
  const handleClick = () => setShow(!show);
  const handleOldClick = () => setShowOld(!showOld);
  const handleNotificationToggle = () => {
    setIsNotificationToggled(!isNotificationToggled);
  };

  const handleEmailToggle = () => {
    setIsEmailToggled(!isEmailToggled);
  };
  const handleChange = (date) => {
    const expire = extractMonthAndYear(date);
    // const expire = `${month}/${year.slice(2, 3)}`;
    if (!expire) {
      message.error("Please enter future date");
    }
    setSelectedDate(date);
    setSelectedExpireDate(expire);
    setPaymentMethod({
      ...paymentMethod,
      expireDate: expire,
      method: ["Stripe"],
    });
  };
  console.log(selectedDate);

  const setPassword = () => {
    if (!form.old) {
      message.error("Old Password is required");
    } else {
      if (!form.new) {
        message.error("New Password is required");
      } else {
        if (!form.confirm) {
          message.error("Confirm Password is required");
        } else {
          if (form.new !== form.confirm) {
            message.error("Password Mismatch");
          } else {
            userRequest
              .put("/partner/user/updatePassword", {
                oldPassword: form.old,
                newPassword: form.new,
              })
              .then(() => {
                message.success("Password Updated");
                setForm(initialState);
              })
              .catch((error) => {
                console.log("error:", error);

                message.error(error?.response?.data?.message);
              });
          }
        }
      }
    }
  };

  const getPaymentMethod = () => {
    userRequest
      .get("/partner/user/getPaymentDetails")
      .then((res) => {
        setPaymentMethod(res.data.data);
        setState("payment");
        return res.data.data;
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
            "Failed to fetch payment details API error 400 bad request."
        );
      });
  };

  const updatePaymentMethod = () => {
    userRequest
      .put("partner/user/updatePaymentDetails", paymentMethod)
      .then((res) => {
        // setPaymentMethod(res.data.data)
        message.success("Payment method updated successfully");
        return res.data.data;
      });
  };
  const handleCvvChange = (e) => {
    // Ensure the input value does not exceed 4 characters
    if (e.target.value.length <= 4) {
      setPaymentMethod({ ...paymentMethod, cvv: e.target.value });
    }
  };

  const updateEmail = () => {
    if (!email.oldEmail) {
      message.error("Old email is required");
    } else {
      if (!email.newEmail) {
        message.error("New email is required");
      } else {
        userRequest
          .put("/partner/user/updateEmail", email)
          .then(() => {
            message.success("Email Updated");
            setEmail(initialStateEmail);
          })
          .catch((error) => {
            console.log("error:", error);

            message.error(error?.response?.data?.message);
          });
      }
    }
  };
  const customTheme = extendTheme({
    colors: {
      aqua: {
        50: "#e0f7fa",
        100: "#b3e7f7",
        200: "#81d6f5",
        300: "#4fc4f2",
        400: "#26b1ee",
        500: "#00a2e8",
        600: "#0092d1",
        700: "#0080b8",
        800: "#006ea0",
        900: "#00598c",
      },
    },
  });
  return (
    <div className={`${styles.mainContainer} overflow-auto`}>
      <Helmet>
        <title> Settings - NETME</title>
      </Helmet>
      <div className={styles.firstDiv}></div>
      <h1 className="font-bold text-3xl pt-28 pl-8 md:pt-10 lg:pt-0 md:pl-0">
        Settings
      </h1>
      <div className={`${styles.container}`}>
        <div>
          <div
            className={` cursor-pointer hover:bg-gray-100 ${styles.toggle}`}
            id={state === "reset" && styles.active}
            onClick={() => setState("reset")}
          >
            <p>Change Password</p>
          </div>
          <div
            className={`cursor-pointer hover:bg-gray-100 ${styles.toggle}`}
            onClick={handleNotificationToggle}
          >
            <p>Website Panel Notification</p>
            <ChakraProvider theme={customTheme}>
              <Switch colorScheme="aqua" isChecked={isNotificationToggled} />
            </ChakraProvider>
          </div>
          <div
            className={`cursor-pointer hover:bg-gray-100 ${styles.toggle}`}
            onClick={handleEmailToggle}
          >
            <p>Email Notification</p>
            <ChakraProvider theme={customTheme}>
              <Switch colorScheme="aqua" isChecked={isEmailToggled} />
            </ChakraProvider>
          </div>
          <div
            className={`cursor-pointer hover:bg-gray-100 ${styles.toggle}`}
            id={state === "plan" && styles.active}
            onClick={() => setState("plan")}
          >
            <p value="">My Active Plan</p>
          </div>
          <div
            className={`cursor-pointer hover:bg-gray-100 ${styles.toggle}`}
            id={state === "email" && styles.active}
            onClick={() => setState("email")}
          >
            <p value="">Change Email ID</p>
          </div>
          <div
            className={`cursor-pointer hover:bg-gray-100 ${styles.toggle}`}
            id={state === "payment" && styles.active}
            onClick={() => {
              getPaymentMethod();
            }}
          >
            <p value="">Change Payment Details</p>
          </div>
        </div>
        <div
          className={state === "plan" ? styles.password : styles.resetPassword}
        >
          {state === "reset" && (
            <>
              <p>Old Password</p>
              <InputGroup size="lg">
                <Input
                  placeholder="Enter Old Password"
                  type={showOld ? "text" : "password"}
                  onChange={(e) => setForm({ ...form, old: e.target.value })}
                  value={form.old}
                />
                <div className="hidden md:block">
                  <InputRightElement width="4.5rem">
                    <p h="1.75rem" size="sm" onClick={handleOldClick}>
                      {showOld ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </p>
                  </InputRightElement>
                </div>
              </InputGroup>
              <p>New Password</p>

              <InputGroup size="lg">
                <Input
                  placeholder="Enter new Password"
                  type={show ? "text" : "password"}
                  onChange={(e) => setForm({ ...form, new: e.target.value })}
                  value={form.new}
                />
                <div className="hidden md:block">
                  <InputRightElement width="4.5rem">
                    <p h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </p>
                  </InputRightElement>
                </div>
              </InputGroup>
              <p>Confirm Password</p>
              <InputGroup size="lg">
                <Input
                  placeholder="Confirm new Password"
                  type={show ? "text" : "password"}
                  onChange={(e) =>
                    setForm({ ...form, confirm: e.target.value })
                  }
                  value={form.confirm}
                />
                <div className="hidden md:block">
                  <InputRightElement width="4.5rem">
                    <p h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </p>
                  </InputRightElement>
                </div>
              </InputGroup>

              <Button id={styles.submit} onClick={setPassword}>
                Submit
              </Button>
            </>
          )}
          {state === "email" && (
            <>
              <p>Old Email ID*</p>
              <Input
                placeholder="Enter Old Email id"
                type="email"
                onChange={(e) =>
                  setEmail({ ...email, oldEmail: e.target.value })
                }
                value={email.oldEmail}
              />
              <p>New Email ID*</p>
              <Input
                placeholder="Enter New Email id"
                type="email"
                onChange={(e) =>
                  setEmail({ ...email, newEmail: e.target.value })
                }
                value={email.newEmail}
              />
              <Button id={styles.submit} onClick={updateEmail}>
                Save Changes
              </Button>
            </>
          )}
          {state === "payment" && (
            <>
              <p>Card Number*</p>
              <Input
                placeholder="Enter Card Number"
                type="number"
                onChange={(e) =>
                  setPaymentMethod({
                    ...paymentMethod,
                    cardNumber: e.target.value,
                  })
                }
                value={
                  paymentMethod && paymentMethod.cardNumber
                    ? paymentMethod.cardNumber
                    : ""
                }
              />
              <div className={styles.innerPayment}>
                <span>
                  <label for="expiry">Expire Date (MM/YY)*</label>
                  <DatePicker
                    value={
                      paymentMethod && paymentMethod.expireDate
                        ? paymentMethod.expireDate
                        : ""
                    }
                    selected={selectedDate}
                    onChange={handleChange}
                    dateFormat="MM/yy"
                    showMonthYearPicker
                    isClearable
                    className={styles.monthStyles}
                    placeholderText="MM/YY"
                  />
                </span>
                <span>
                  <p>CVV (4 Digit)*</p>
                  <Input
                    maxLength={4}
                    type="password"
                    placeholder="CVV"
                    onChange={handleCvvChange}
                    value={
                      paymentMethod && paymentMethod.cvv
                        ? paymentMethod.cvv
                        : ""
                    }
                  />
                </span>
              </div>
              <p>Postal Code*</p>

              <Input
                placeholder="Enter Postal Code"
                type="number"
                onChange={(e) =>
                  setPaymentMethod({
                    ...paymentMethod,
                    postalCode: e.target.value,
                  })
                }
                value={
                  paymentMethod && paymentMethod.postalCode
                    ? paymentMethod.postalCode
                    : ""
                }
              />
              <div className={styles.payMentMethod}>
                <div className={styles.innerPayment}>
                  <input
                    type="radio"
                    defaultChecked
                    style={{ backgroundColor: "black" }}
                  />
                  <p>Stripe pay</p>
                </div>
                <img src={stripe} alt="" className="w-28 h-16" />
              </div>
              <Button id={styles.submit} onClick={updatePaymentMethod}>
                Save Changes
              </Button>
            </>
          )}
          {state === "plan" && <ActivePlan />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
