import React from "react";
import styles from "../style.module.css";
import DeleteModel from "../DeleteModel2";
import { Pagination } from "antd";
import { useState } from "react";
import RequestedAds from "./RequestedAds/RequestedAds";
import ReasonModel from "./Reason";
const Overview = ({ ads, total, page, setPage }) => {
  const [selectedAdId, setSelectedAdId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedAdId(null);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    // Get day with suffix
    function getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return day + "th";
      }
      switch (day % 10) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    }

    // Define months as an array for formatting
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Format the date manually
    const formattedDate =
      getDayWithSuffix(date.getDate()) +
      " " +
      months[date.getMonth()] +
      ", " +
      date.getFullYear();

    return formattedDate;
  }

  if (ads.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }
  const updateAds = ads?.filter((ad) => ad.status !== "Cancelled");

  return (
    <div className={styles.tableDiv}>
      <div
        style={{
          display: "block",
          width: 700,
          padding: 30,
        }}
      ></div>
      <table className={`flex auto border-2 border-gray-200 ${styles.table}`}>
        <div className="w-full h-[300px] md:h-auto md:max-h-[60vh] overflow-auto rounded-xl">
          <tr className="flex z-10 relative justify-center w-[300%] items-center md:w-[200%] lg:w-full px-2">
            <th className="w-[17%] truncate">Campaign Name</th>
            <th className="w-[17%] truncate">Ad Title</th>
            <th className="w-[17%] truncate">Ad body</th>
            <th className="w-[17%] truncate">Ad Type</th>
            <th className="w-[17%] truncate">Release on</th>
            <th className="w-[17%] truncate">Created on</th>
            <th className="w-[17%] truncate">Status</th>
            <th className="w-[17%] truncate">Action</th>
          </tr>

          {updateAds?.map((el, i) => {
            const rowContent = (
              <tr
                key={i}
                className={`${
                  el?.status === "Rejected" ? "h-[100px] " : "h-[50px]"
                } cursor-pointer hover:bg-gray-100 transition-all delay-75 ease-out border-t border-gray-200 flex justify-between w-[300%] md:w-[200%] lg:w-full items-center px-2`}
              >
                <td className="w-[17%] truncate">{el.name}</td>
                <td className="w-[17%] truncate">
                  {el.adType === "Suggestion" ? "-" : el.title}
                </td>
                <td className="w-[17%] truncate">
                  {el.adType === "Suggestion" ? "-" : el.body}
                </td>
                <td className="w-[17%] truncate">{el.adType}</td>
                <td className="w-[17%] truncate">
                  {formatDate(el.releaseDate)}
                </td>
                <td className="w-[17%] truncate">{formatDate(el.createdAt)}</td>
                <td className="w-[17%] truncate flex justify-center items-center">
                  <div
                    className={`px-2 py-1 rounded-lg font-bold ${
                      el.status === "Pending" || el.status === "Requested"
                        ? "bg-[#FFF7EF] text-[#E98015]"
                        : ""
                    } ${
                      el.status === "Approved" &&
                      "bg-[#41c9b41a] text-[#8CE9DB] px-2"
                    }
              ${el.status === "Rejected" && "bg-[#DB3B531A] text-[#DB3B53]"}
              ${el.status === "Deleted" && "bg-[#DB3B531A] text-[#f35a46]"}
              ${el.status === "Cancelled" && "bg-[#DB3B531A] text-[#DB3B53]"}
              ${el.status === "Ended" && "text-[#333333] opacity-50"}
              `}
                  >
                    {el.status}
                  </div>
                </td>
                <td className="w-[17%]">
                  <div
                    className={styles.actionDiv}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* <DeleteModel text="Advertisement plan" /> */}
                    {el?.status === "Rejected" ? (
                      <div className="flex flex-col gap-2">
                        <ReasonModel
                          el={el}
                          page={page}
                          partnerAd={true}
                          title="reason"
                        />
                        <ReasonModel
                          el={el}
                          page={page}
                          partnerAd={true}
                          title="cancel"
                        />
                      </div>
                    ) : (
                      <DeleteModel el={el} page={page} partnerAd={true} />
                    )}
                  </div>
                </td>
              </tr>
            );

            return el.status === "Requested" ? (
              <button
                key={el._id}
                className="w-full"
                onClick={() => {
                  // Set the isModalVisible prop of the RequestedAds component to true
                  setIsModalVisible(true);
                  // Optionally, pass the ad details (el) as a prop to RequestedAds
                  setSelectedAdId(el._id);
                }}
              >
                {rowContent}
              </button>
            ) : (
              rowContent
            );
          })}
          {isModalVisible && (
            <RequestedAds
              id={selectedAdId}
              onClose={closeModal}
              isModalVisible={isModalVisible}
            />
          )}
        </div>
      </table>

      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            current={page}
            total={total}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Overview;
