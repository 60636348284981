import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { Helmet } from "react-helmet";
import Chart from "react-google-charts";
import svg2 from "../../assets/svg2.svg";
import svg1 from "../../assets/svg1.svg";
import svg3 from "../../assets/sms.svg";
import LogoutArrow from "../../assets/usersDashboard/LogoutArrow.svg";
import { FiLogOut } from "react-icons/fi";
import {
  AiFillPrinter,
  AiOutlineDownload,
  AiOutlineShareAlt,
} from "react-icons/ai";
import QRCode from "qrcode.react";

import { userRequest } from "../../requestMethod";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/AuthReducer";
import { Dropdown } from "antd";
import { Link } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import ShareModal from "./ShareModal";

const Dashboard = () => {
  const dispatch = useDispatch();
  const qrCodeRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
  });
  const [ageData, setAgeData] = useState([]);
  const [timePeriod, setTimePeriod] = useState("Daily");
  const [ageGraphFilter, setAgeGraphFilter] = useState("Daily");

  const [loading, setLoading] = useState(true);
  const [ageLoading, setAgeLoading] = useState(true);
  const [data, setData] = useState({
    impressions: 0,
    clicks: 0,
    invites: 0,
  });
  const [chartData, setChartData] = useState();
  console.log("data", data);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userRequest.get(
        `partner/analytics/getDashboardAnalytics?timePeriod=${timePeriod}`
      );
      const {
        chartData,
        actionTypeImpressionCount,
        actionTypeClickCount,
        totalInvitationCount,
      } = response.data;
      setData({
        impressions: actionTypeImpressionCount,
        clicks: actionTypeClickCount,
        invites: totalInvitationCount,
      });

      setChartData(chartData);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAgeData = async () => {
    setAgeLoading(true);
    try {
      const response = await userRequest.get(
        `partner/analytics/getAgeDashboardAnalytics?timePeriod=${ageGraphFilter}`
      );
      const {
        totalAgeCount,
        actionTypeImpressionCount,
        actionTypeClickCount,
        totalInvitationCount,
      } = response.data;

      setData({
        impressions: actionTypeImpressionCount,
        clicks: actionTypeClickCount,
        invites: totalInvitationCount,
      });

      setAgeData(totalAgeCount);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setAgeLoading(false);
    }
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("userData"));
    if (storedUser) {
      setUser({
        id: storedUser._id,
        name: storedUser.fullName + " " + storedUser.lastName,
        email: storedUser.email,
      });
    }
  }, []);

  useEffect(() => {
    fetchAgeData();
  }, [ageGraphFilter]);

  useEffect(() => {
    fetchData();
  }, [timePeriod]);

  const handleTimePeriodChange = (event) => {
    const selectedTimePeriod = event.target.value;
    setTimePeriod(selectedTimePeriod);
  };
  const handleGraphFilterChange = (event) => {
    const selectedGraphFilter = event.target.value;
    setAgeGraphFilter(selectedGraphFilter);
  };

  const preparePerformanceData = () => {
    if (!chartData || chartData.length === 0) {
      return [
        ["Day", "Impressions", "Clicks", "Invites"],
        [0, 0, 0, 0], // Add a single row with zero values
      ];
    }

    const isWeeklyData = chartData[0]?.hasOwnProperty("day");
    console.log("chartData", chartData);
    if (isWeeklyData) {
      const dayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      const sortedData = chartData?.sort(
        (a, b) => dayOrder?.indexOf(a.day) - dayOrder?.indexOf(b.day)
      );
      return [
        ["Day", "Impressions", "Clicks", "Invites"],
        ...sortedData?.map((item) => [
          item.day,
          item.actionTypeImpressionCount,
          item.actionTypeClickCount,
          item.invitationCount,
        ]),
      ];
    } else {
      const sortedData = chartData?.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      );
      console.log("sortedData", sortedData);
      return [
        ["Day", "Impressions", "Clicks", "Invites"],
        ...sortedData?.map((item) => [
          formatDate(item?.date, timePeriod),
          item?.actionTypeImpressionCount,
          item?.actionTypeClickCount,
          item?.invitationCount,
        ]),
      ];
    }
  };

  const formatDate = (dateString, timePeriod) => {
    const date = new Date(dateString);
    switch (timePeriod) {
      case "Daily":
      case "Yesterday":
        return date.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
        });
      case "14Days":
      case "30Days":
        return date.toLocaleString("en-US", { month: "short", day: "numeric" });
      default:
        return dateString;
    }
  };

  const performanceOptions = {
    title: "",
    legend: { position: "none" },
    hAxis: { textPosition: "none" },
    vAxis: {
      textPosition: "none",
      format: "0",
      viewWindow: { min: 0 }, // Ensure the y-axis starts at 0
    },
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    series: {
      0: { color: "#8CC9E9", bar: { groupWidth: "10%" } },
      1: { color: "#8CE9DB", bar: { groupWidth: "10%" } },
      2: { color: "#333333", bar: { groupWidth: "10%" } },
    },
  };

  console.log("ageData", ageData);
  const prepareAgeData = () => {
    if (!ageData || ageData.length === 0) {
      return [
        ["Age Range", "Percentage"],
        ["No Data", 1],
      ];
    }

    return [
      ["Age Range", "Percentage"],
      ...ageData.map((item) => [
        `${item.ageRange} (${parseFloat(item.percentage).toFixed(1)}%)`,
        parseFloat(item.percentage),
      ]),
    ];
  };

  const ageOptions = {
    title: "",
    colors: ["#333333", "#8CC9E9", "#8CE9DB"],
    chartArea: { left: 0, top: 0, width: "100%", height: "60%" },
    legend: {
      position: "center",
      textStyle: {
        fontSize: 8,
        fontWeight: 800,
      },
    },
  };

  const items = [
    {
      label: (
        <div className={styles.profileDetails}>
          {/* <Avatar name="P" bg="blue.300" size="md" /> */}
          <div className="w-[45px] h-[45px] bg-[#63b3ed] rounded-full relative">
            <p className="text-2xl text-white font-semibold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              {user.name.charAt(0).toUpperCase()}
            </p>
          </div>
          <div className={styles.profilePic}>
            <p style={{ fontWeight: "500" }}>{user.name}</p>
            <p style={{ fontWeight: "500" }}>{user.email}</p>
          </div>
        </div>
      ),
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: (
        <span
          className={styles.logout}
          onClick={() => {
            dispatch(logout());
            window.location.reload();
          }}
        >
          {" "}
          <FiLogOut />
          Log Out
        </span>
      ),
      key: "1",
    },
  ];
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [print, setPrint] = useState();
  useEffect(() => {
    function handleResize() {
      window.location.reload();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setIsNavbarFixed(position > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const downloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const image = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.href = image;
    link.download = "qrcode.png";
    link.click();
  };
  const shareQRCode = () => {
    onOpen();
    if (navigator.share) {
      navigator.share({
        title: "QR Code",
        text: "Check out this QR code!",
        url: "http://yourserver.com/qrcode",
      });
    }
  };
  const handlePrint = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const dataUrl = canvas.toDataURL();
    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.write(`
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              margin: 0;
            }
          </style>
        </head>
        <body>
        <img src="${dataUrl}" style="width: 400px; height: 400px;" />
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.onload = () => {
      newWindow.print();
      newWindow.close();
    };
  };
  return (
    <>
      {!print && (
        <div className={`${styles.MainContainer} ${print && "hidden"}`}>
          <Helmet>
            <title>My Dashboard - NETME</title>
          </Helmet>
          <div
            className={`${styles.firstContainer}  ${
              isNavbarFixed ? styles.fixedNavbar : ""
            }`}
          >
            <h1 className="hidden text-2xl font-bold md:text-3xl md:block md:pt-5 lg:pt-0">
              Dashboard Overview
            </h1>
            <div className={styles.headWrap}>
              <select
                className="cursor-pointer"
                style={{ direction: "rtl" }}
                onChange={handleGraphFilterChange}
                value={ageGraphFilter}
              >
                <option value="Daily">Today</option>
                <option value="Yesterday">Yesterday</option>
                <option value="Weekly">Last Week</option>
                <option value="14Days">Last 14 Days</option>
                <option value="30Days">Last 30 Days</option>
              </select>
              <div className="cursor-pointer w-[50px] h-[45px] rounded-2xl relative border-2 border-[#d9d9d9]">
                <svg
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                >
                  <path
                    d="M17.302 12.9992V7.99923C17.2731 6.40555 16.6197 4.8869 15.4824 3.77007C14.3452 2.65325 12.8149 2.02752 11.2209 2.02752C9.627 2.02752 8.09674 2.65325 6.95947 3.77007C5.82221 4.8869 5.1688 6.40555 5.13989 7.99923V12.9992C5.14086 13.7023 4.95373 14.3928 4.5979 14.9992H17.845C17.4888 14.3929 17.3013 13.7024 17.302 12.9992ZM21.356 16.9992H1.08594C0.820721 16.9992 0.566443 16.8939 0.378906 16.7063C0.19137 16.5188 0.0859375 16.2644 0.0859375 15.9992C0.0859375 15.734 0.19137 15.4797 0.378906 15.2921C0.566443 15.1046 0.820721 14.9992 1.08594 14.9992C1.61991 15.0027 2.13344 14.7939 2.51355 14.4189C2.89366 14.0439 3.10921 13.5332 3.11292 12.9992V7.99923C3.14634 5.87091 4.01532 3.84107 5.53223 2.3478C7.04914 0.85453 9.09236 0.0175781 11.2209 0.0175781C13.3495 0.0175781 15.3928 0.85453 16.9097 2.3478C18.4266 3.84107 19.2956 5.87091 19.329 7.99923V12.9992C19.3327 13.5332 19.5482 14.0439 19.9283 14.4189C20.3085 14.7939 20.822 15.0027 21.356 14.9992C21.6212 14.9992 21.8756 15.1046 22.0631 15.2921C22.2506 15.4797 22.356 15.734 22.356 15.9992C22.356 16.2644 22.2506 16.5188 22.0631 16.7063C21.8756 16.8939 21.6212 16.9992 21.356 16.9992ZM13.851 20.4992C13.5796 20.9549 13.1946 21.3322 12.7335 21.5943C12.2725 21.8563 11.7513 21.9941 11.2209 21.9941C10.6906 21.9941 10.1694 21.8563 9.70837 21.5943C9.24733 21.3322 8.86227 20.9549 8.59094 20.4992C8.50269 20.3464 8.45643 20.1729 8.45691 19.9965C8.45739 19.82 8.50456 19.6468 8.59363 19.4944C8.6827 19.3421 8.81056 19.216 8.96411 19.1291C9.11767 19.0421 9.29143 18.9973 9.4679 18.9992H12.975C13.1514 18.9975 13.3251 19.0424 13.4785 19.1294C13.6319 19.2165 13.7596 19.3426 13.8485 19.4949C13.9375 19.6472 13.9846 19.8203 13.985 19.9967C13.9854 20.1731 13.9392 20.3465 13.851 20.4992Z"
                    fill="#809FB8"
                  />
                </svg>
              </div>

              <div id={styles.profileImg} className="cursor-pointer">
                {/* <img src="https://github.com/shadcn.png" alt="" /> */}
                <div
                  className="w-[45px] h-[45px] rounded-2xl relative"
                  style={{
                    background:
                      "linear-gradient(to right, #3ab5b0 0%, #3d99be 31%, #56317a 100%)",
                  }}
                >
                  <p className="text-xl text-white font-semibold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    {user.name.charAt(0).toUpperCase()}
                  </p>
                </div>
              </div>
              <div className={`${styles.logoutArrow} cursor-pointer`}>
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <Link onClick={(e) => console.log(e)}>
                    <img src={LogoutArrow} alt="" />
                  </Link>
                </Dropdown>
              </div>
            </div>
          </div>
          <h1 className="text-3xl pl-5 mt-32 font-bold md:hidden">
            Dashboard Overview
          </h1>
          <div className={styles.cards}>
            <div className={styles.cardsInner}>
              <div className={styles.card}>
                <img src={svg1} alt="" />
                <p>Impressions</p>
                <h1>{data.impressions ? data.impressions : 0}</h1>
              </div>
              <div className={styles.card}>
                <img src={svg2} alt="" />
                <p>Clicks</p>
                <h1>{data.clicks ? data.clicks : 0}</h1>
              </div>
              <div className={styles.card}>
                <img src={svg3} alt="" />
                <p>Invites</p>
                <h1>{data.invites ? data.invites : 0}</h1>
              </div>
            </div>

            <div className={styles.pieDiv}>
              <div className={styles.title}>Avg. age of Customer</div>
              <div className="w-[100%]">
                {ageLoading ? (
                  <div className="ml-8">Loading...</div>
                ) : ageData?.length ? (
                  <Chart
                    chartType="PieChart"
                    data={prepareAgeData()}
                    options={ageOptions}
                  />
                ) : (
                  <div className="ml-8">
                    No data available to display value is 0
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.chartDiv}>
            <div className="w-full md:w-[70%]">
              <div className={styles.chart}>
                <div className={styles.chartHead}>
                  <h2 className="mr-3 md:mr-0">Performance on NETME App</h2>
                  <div className={styles.innerChartHead}>
                    <select
                      className="cursor-pointer"
                      style={{ direction: "rtl" }}
                      onChange={handleTimePeriodChange}
                      value={timePeriod}
                    >
                      <option value="Daily">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Weekly">Last Week</option>
                      <option value="14Days">Last 14 Days</option>
                      <option value="30Days">Last 30 Days</option>
                    </select>
                  </div>
                </div>
                <div className={styles.performanceChartContainer}>
                  {loading ? (
                    <div>Loading...</div>
                  ) : !chartData?.length ? (
                    <div className={styles.noDataOverlay}>
                      <p>No data available for the selected time period.</p>
                    </div>
                  ) : (
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="340px"
                      data={preparePerformanceData()}
                      options={performanceOptions}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.sideTable}>
              <div className={styles.printDiv}>
                <h2>Your QR code</h2>
                <span
                  className={`cursor-pointer ${styles.shareIcon}`}
                  onClick={shareQRCode}
                >
                  {" "}
                  <AiOutlineShareAlt /> Share
                </span>
              </div>
              <ShareModal isOpen={isOpen} onClose={onClose} />
              <div
                className={`h-auto md:h-[90%] overflow-auto ${styles.QrDiv}`}
                ref={qrCodeRef}
                id="print-image"
              >
                <QRCode value={`https://github.com/shadcn.png`} />
              </div>
              <div className={styles.printDiv}>
                <span
                  className={`cursor-pointer ${styles.printIcon}`}
                  onClick={handlePrint}
                >
                  {" "}
                  <AiFillPrinter />
                  Print
                </span>
                <span
                  className={`cursor-pointer ${styles.printIcon}`}
                  onClick={downloadQRCode}
                >
                  {" "}
                  <AiOutlineDownload /> Download
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {print && (
        <div
          className={`h-auto md:h-[90%] overflow-auto ${styles.QrDiv}`}
          ref={qrCodeRef}
          id="print-image"
        >
          <QRCode value={`https://github.com/shadcn.png`} />
        </div>
      )}
    </>
  );
};

export default Dashboard;
